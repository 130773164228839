<template>
	<WwTransitionlessModal v-if="!isAgeConfirmed">
		<div class="flex flex-wrap p-4 text-left">
			<div class="w-full mb-4 max-w-200">
				<img
					height="30"
					width="180"
					src="@/assets/site-logos/logo-full.svg"
					alt="Where's Weed logo"
				>
			</div>
			<div class="w-full mb-4 text-2xl font-bold text-black">
				Are you at least 21 years old or a valid medical patient?
			</div>
			<div class="grid w-full grid-cols-1 gap-3 mb-4 md:grid-cols-2">
				<button
					class="w-full py-2 font-semibold text-white uppercase bg-green-500 border border-green-500 rounded focus:ring focus:ring-green-300 focus:border-gray-300"
					@click="setAgeConfirmed"
				>
					Yes
				</button>
				<button
					class="w-full py-2 font-semibold uppercase bg-white border border-gray-300 rounded focus:ring focus:ring-green-300 focus:border-gray-300"
					@click="decline"
				>
					No
				</button>
			</div>
			<div class="w-full text-gray-500">
				Where's Weed strives to abide by all state laws and regulations in regards to access to cannabis.
			</div>
		</div>
	</WwTransitionlessModal>
</template>

<script defer>
import WwTransitionlessModal from '@/components/multiUse/WwTransitionlessModal.vue'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		WwTransitionlessModal
	},
	data () {
		return {
			isAgeConfirmed: false,
			storageVariable: 'wwAgeConfirmed'
		}
	},
	mounted () {
		try {
			this.isAgeConfirmed = Boolean(localStorage.getItem(this.storageVariable))
		} catch (e) {
			logError(e)
			this.isAgeConfirmed = true
		}
	},
	methods: {
		setAgeConfirmed () {
			try {
				localStorage.setItem(this.storageVariable, true)
			} catch (e) {
				logError(e)
			}
			this.isAgeConfirmed = true
		},
		decline () {
			location.assign('https://duckduckgo.com')
		}
	}
}
</script>

<style lang="scss" scoped>
.max-w-200 {
	max-width: 200px;
}
</style>
