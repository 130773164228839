
const destructureStrain = function (data, cannabisType, dsType) { // accepts a strain object and type ("marijuana" OR "hemp")
	if (dsType === 'single') { // for single strain. dsType == 'single'
		const {
			strain_id: id,
			strain_image: image,
			strain_type: type,
			strain_name: name,
			strain_description: description,
			images,
			business_count: businessCount,
			url,
			seo_url: seo,
			rating: overall,
			rating_count: ratingCount,
			rating_breakdown: breakdown,
			rating_groups: ratingGroups
		} = data.strain

		return {
			strain: {
				id,
				image,
				type,
				name,
				description,
				images,
				businessCount,
				url,
				seo,
				ratings: {
					overall,
					ratingCount,
					breakdown,
					cannabisType: cannabisType,
					ratingGroups
				}
			},
			businesses: data.businesses
			// menus: data.menus
		}
	} else { // for list of strains. dsType == 'list'
		const {
			id,
			image,
			type,
			name,
			business_count: businessCount,
			seo_url: seo,
			rating
		} = data

		return {
			id,
			image,
			type,
			name,
			businessCount,
			seo,
			rating
		}
	}
}

export default destructureStrain
