import './registerServiceWorker.js'
import '@/assets/scss/main.scss'

import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import ClickOutside from 'v-click-outside'
import Vue from 'vue'
import VueApollo from 'vue-apollo'
import VueGtag from 'vue-gtag'
import VueMeta from 'vue-meta'

import { createApolloClient } from '@/api/apollo/client.js'
import { APP_NODE_ENV, APP_VERSION } from '@/constants/index.js'
import animateOnScroll from '@/directives/animateOnScroll.js'
import trackEvent from '@/directives/eventTracking.js'
import invalidInput from '@/directives/invalidInput.js'
import requireLogin from '@/directives/requireLogin.js'
import ripple from '@/directives/ripple.js'

import App from './App.vue'
import { createRouter } from './router.js'
import { createStore } from './store/index.js'

Vue.directive('ripple', ripple)
Vue.directive('require-login', requireLogin)
Vue.directive('invalid', invalidInput)
Vue.directive('track-event', trackEvent)
Vue.directive('ripple', ripple)
Vue.directive('animateOnScroll', animateOnScroll)

Vue.use(VueMeta, {
	refreshOnceOnNavigation: true
})

Vue.use(VueApollo)

Bugsnag.start({
	apiKey: '9a3cb8b4d22437e4adda0bbbb8c1d065',
	plugins: [ new BugsnagPluginVue() ],
	appVersion: APP_VERSION,
	releaseStage: APP_NODE_ENV
})
const bugsnagVue = Bugsnag.getPlugin('vue')
bugsnagVue.installVueErrorHandler(Vue)

Vue.use(ClickOutside)

Vue.config.productionTip = false
Vue.config.errorHandler = (err) => {
	Bugsnag.notify(err)
}

export function createApp() {
	const apolloClient = createApolloClient()
	const apolloProvider = new VueApollo({
		defaultClient: apolloClient,
		defaultOptions: {
			$loadingKey: 'loading'
		}
	})

	const router = createRouter()
	const store = createStore()

	Bugsnag.addOnError(function (event) {
		if (router.history?.pending || router.currentRoute) {
			event.addMetadata('route', {
				pending: router.history?.pending?.fullPath,
				current: router.currentRoute?.fullPath,
				auth: {
					username: store.state?.auth?.username,
					id: store.state?.auth.id
				},
				user: {
					username: store.state?.user?.username,
					id: store.state?.user?.id
				},
				error: {
					message: store.state?.error,
					code: store.state?.errorCode
				}
			})
		}
	})

	Vue.use(VueGtag, {
		config: { id: 'G-7PGB1QJEDB' },
		disableScriptLoad: true
	}, router
	)

	const APP = new Vue({
		apolloProvider,
		router,
		store,
		render: h => h(App)
	})
	return {
		APP, router, store, apolloProvider
	}
}
