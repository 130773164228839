import Vue from 'vue'
import Router from 'vue-router'

import stateParamMatch from '@/constants/state/state-param-match.js'
import { logError } from '@/utils/error-handling.js'
import blogRoutes from '@/views/blog/routes.js'
import businessRoutes from '@/views/business/routes.js'
import chainRoutes from '@/views/chains/routes.js'
import cityRoutes from '@/views/city/routes.js'
import dabventRoutes from '@/views/dabvent/routes.js'
import stateRoutes from '@/views/state/routes.js'
import strainRoutes from '@/views/strain/routes.js'
import tourRoutes from '@/views/tour/routes.js'
import userRoutes from '@/views/user/routes.js'

// polyfill for duplicate navigation routes
[ 'push', 'replace' ].forEach(method => {
	const originalMethod = Router.prototype[method]
	Router.prototype[method] = function m(location) {
		return originalMethod.call(this, location).catch(error => {
			if (error.name !== 'NavigationDuplicated') {
				// capture exception
				logError(error)
			}
		})
	}
})

Vue.use(Router)

export function createRouter() {
	const router = new Router({
		mode: 'history',
		scrollBehavior(to, from, savedPosition) {
			const scrollElementById = document.getElementById(to.hash.substring(1))
			if (to.hash && scrollElementById) {
				scrollElementById.scrollIntoView()
				return
			} else if (document.getElementById(to.meta.scrollToId)) {
				return {
					selector: to.meta.scrollToId
				}
			}
			if (savedPosition) {
				return savedPosition
			}

			return { x: 0, y: 0 }
		},
		history: 'true',
		routes: [
			{
				path: '/',
				component: () => import(/* webpackChunkName: "home" */ './views/city/_Shell.vue'),
				children: [
					{
						path: '',
						name: 'home',
						component: () => import(/* webpackChunkName: "home" */ './views/city/Index.vue')
					}
				]
			},
			{
				path: '/about',
				name: 'about',
				component: () => import(/* webpackChunkName: "content" */ './views/About.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/join',
				name: 'register',
				component: () => import(/* webpackChunkName: "login" */ './views/Register.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/login',
				name: 'login',
				component: () => import(/* webpackChunkName: "login" */ './views/Login.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/logout',
				name: 'logout',
				component: () => import(/* webpackChunkName: "content" */ './views/Logout.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/error',
				name: 'error',
				component: () => import(/* webpackChunkName: "error" */ './components/error/Error.vue')
			},
			{
				path: '/forgot',
				name: 'forgot',
				component: () => import(/* webpackChunkName: "login" */ './views/Forgot.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/privacy',
				name: 'privacy',
				component: () => import(/* webpackChunkName: "content" */ './views/Privacy.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/terms',
				name: 'terms',
				component: () => import(/* webpackChunkName: "content" */ './views/Terms.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/copyright',
				name: 'copyright',
				component: () => import(/* webpackChunkName: "content" */ './views/Copyright.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/contact',
				name: 'contact',
				component: () => import(/* webpackChunkName: "content" */ './views/ContactHub.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/support',
				name: 'support',
				component: () => import(/* webpackChunkName: "content" */ './views/ContactSupport.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/sales',
				name: 'sales',
				component: () => import(/* webpackChunkName: "content" */ './views/ContactSales.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/advertising',
				name: 'advertising',
				component: () => import(/* webpackChunkName: "content" */ './views/Advertising.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/advocacy',
				name: 'advocacy',
				component: () => import(/* webpackChunkName: "content" */ './views/advocacy/Advocacy.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/contest-rules',
				name: 'contestRules',
				component: () => import(/* webpackChunkName: "content" */ './views/ContestRules.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/get-medical-marijuana-card',
				name: 'leafwellPrimary',
				component: () => import(/* webpackChunkName: "content" */ './views/getMedCard/GetMedCard.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: `/get-medical-marijuana-card/:state(${stateParamMatch})`,
				name: 'leafwellState',
				component: () => import(/* webpackChunkName: "content" */ './views/getMedCard/StateMedCardApplication.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/leaf411',
				name: 'leaf411',
				component: () => import(/* webpackChunkName: "content" */ './views/leaf411/Leaf411.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/partners',
				name: 'partners',
				component: () => import(/* webpackChunkName: "partner" */ './views/Partners.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/partners-new',
				name: 'partnersNew',
				component: () => import(/* webpackChunkName: "partner" */ './views/partners/Index.vue'),
				meta: {
					requireInfo: true
				}
			},
			{
				path: '/partners-new/:partnerName',
				name: 'partner',
				component: () => import(/* webpackChunkName: "partner" */ './views/partners/Partner.vue')
			},
			{
				path: '/search/:searchTerm?',
				name: 'search',
				component: () => import(/* webpackChunkName: "search" */ './views/Search.vue'),
				meta: {
					requireInfo: true
				}
			},
			...dabventRoutes,
			...chainRoutes,
			...blogRoutes,
			...userRoutes,
			...strainRoutes,
			...stateRoutes,
			...tourRoutes,
			...cityRoutes,
			...businessRoutes,
			{
				path: '/guest/pre-order',
				name: 'guestPreOrder',
				component: () => import('./views/GuestPreOrder.vue')
			},
			{
				path: '*',
				name: '404',
				component: () => import(/* webpackChunkName: "error" */ './views/404.vue'),
				meta: {
					requireInfo: true
				}
			}
		]
	})

	return router
}
