import getters from './getters.js'
import mutations from './mutations.js'

const initialState = {
	toasts: []
}

export default {
	state: { ...initialState },
	namespaced: true,
	getters,
	mutations
}
