export const VALID_EMAIL_ERROR = 'You must enter a VALID email to submit a report!'
export const NO_MATCHING_ACCOUNT = 'Account Not Found: No matching username and email combination were found!'
export const USERNAME_LENGTH_ERROR = 'Usernames Must be Between 5-20 Characters!'
export const PASSWORD_LENGTH_ERROR = 'Passwords Must be Between 6-20 Characters!'
export const PASSWORD_MISMATCH = 'Passwords DO NOT Match!'
export const DUPLICATE_EMAIL = 'Duplicate Email Found!'
export const AGE_LIMIT = 'You Must be 21+ Years of Age or a Medical Patient to Signup'
export const NO_RESULTS = 'No Results Found'
export const GENERIC_ERROR_MESSAGE = 'Oh no! There was a problem. Please try again.'

export const ERROR_MESSAGE_WHITELIST = [
	String(NO_MATCHING_ACCOUNT).valueOf(),
	String(DUPLICATE_EMAIL).valueOf(),
	String(USERNAME_LENGTH_ERROR).valueOf(),
	String(PASSWORD_LENGTH_ERROR).valueOf(),
	String(PASSWORD_MISMATCH).valueOf(),
	String(AGE_LIMIT).valueOf()
]

export const ERROR_MESSAGE_BLACKLIST = [
	String(NO_RESULTS).valueOf()
]

export const REMAPPED_ERROR_MESSAGES = [
	{
		originalMessage: String(VALID_EMAIL_ERROR).valueOf(),
		newMessage: 'Please enter a valid email address.'
	}
]
