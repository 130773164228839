const mapJsonLd = (data) => {
	if (typeof data === 'string') {
		return data.replace('scripttype', 'script type') // this shouldnt be a thing but api is broked
	}
	if (typeof data === 'object') {
		return `<script type="application/ld+json">${JSON.stringify(data)}</script>`
	}
	return ''
}

export default mapJsonLd
