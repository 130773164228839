import uniqueId from 'lodash.uniqueid'

import { SUCCESS } from '@/constants/toast/type.js'

export default {
	showToast(state, {
		primaryText,
		secondaryText,
		delay = 5000,
		type = SUCCESS,
		link
	}) {
		const id = uniqueId('toast-')
		const toast = {
			primaryText: primaryText,
			secondaryText: secondaryText,
			delay: delay,
			type: type,
			link: link,
			id
		}
		state.toasts.unshift(toast)

		setTimeout(() => {
			state.toasts = state.toasts.filter(toast => {
				return id !== toast.id
			})
		}, delay)

		return id
	},
	closeToast(state, toastId) {
		state.toasts = state.toasts.filter(toast => {
			return toast.id !== toastId
		})
	}
}
