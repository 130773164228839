import Bugsnag from '@bugsnag/js'

import {
	ERROR_MESSAGE_BLACKLIST,
	ERROR_MESSAGE_WHITELIST,
	GENERIC_ERROR_MESSAGE,
	REMAPPED_ERROR_MESSAGES
} from '@/constants/errorHandling/error-messages.js'
import { APP_NODE_ENV } from '@/constants/index.js'
import { ERROR } from '@/constants/toast/type.js'

const getErrorMessage = error => {
	const errorString = error
	const errorObjectMessage = error?.message
	const responseErrorMessage = error?.response?.data?.errors[0].message
	let errorMessage = ''

	if (typeof errorString === 'string') {
		errorMessage = errorString.trim()
	}
	if (errorObjectMessage) {
		errorMessage = errorObjectMessage.trim()
	}
	if (responseErrorMessage) {
		errorMessage = responseErrorMessage.trim()
	}

	if (ERROR_MESSAGE_WHITELIST.includes(String(errorMessage).valueOf())) {
		return errorMessage
	}

	if (errorMessage.length > 0) return sanitizeErrorMessage(String(errorMessage).valueOf())

	return GENERIC_ERROR_MESSAGE
}

export const logError = (error, meta) => {
	const isValidError = !ERROR_MESSAGE_BLACKLIST.includes(String(error?.message).valueOf())
	if (isValidError) {
		Bugsnag.notify(error, (event) => {
			event.addMetadata('passedMeta', meta)
		})
	}
}

export const sanitizeErrorMessage = (message) => {
	const index = REMAPPED_ERROR_MESSAGES.map(object => object.originalMessage).indexOf(message)
	if (index !== -1) return REMAPPED_ERROR_MESSAGES[index].newMessage
	// #NOTE uncomment once full whitelist is implemented
	// return GENERIC_ERROR_MESSAGE
	return message
}

export const showErrorToast = (error, store) => {
	const secondaryText = getErrorMessage(error)
	if (store) {
		store.commit('toast/showToast', {
			primaryText: 'Server Error',
			secondaryText: secondaryText,
			type: ERROR
		}, { root: true })
	}
}

export const gqlError = (error) => {
	if (error?.graphQLErrors?.length) {
		error.graphQLErrors.forEach((errorItem) => {
			const error500 = new Error('500 - GQL Error', { cause: errorItem.message })
			Bugsnag.notify(error500)
		})
	}
}

export const networkError = (error) => {
	if (error?.networkError) {
		const error500 = new Error('500 - Network Error', { cause: error.networkError })
		Bugsnag.notify(error500)
	}
}

export const gqlRedirect = (redirect, store) => {
	store.commit('setError', { status: 301, redirect: redirect ?? '/' })
	if (APP_NODE_ENV === 'development') {
		console.log('301 - Redirect', redirect)
	}
}

export const pageLevelGQLErrors = (error, store) => {
	const redirect = error?.graphQLErrors?.find((e) => e?.code === 301)?.redirect ?? false
	if (redirect) {
		gqlRedirect(redirect, store)
	} else { // if not 301, then 500
		store.commit('setError', { status: 500 })
		gqlError(error)
		networkError(error)
	}
}

export const componentLevelGQLErrors = (error) => {
	gqlError(error)
	networkError(error)
}
