import { LIST } from '@/constants/map/map-modes.js'

import getters from './getters.js'
import mutations from './mutations.js'

const initialState = {
	searchMode: LIST,
	selectedMarker: null,
	selectedBusiness: null
}

export default {
	state: { ...initialState },
	namespaced: true,
	getters,
	mutations
}
