import apiService from '@/api/index.js'

// there needs to be an entry for each route
const routeToFunctionMap = {
	businessIndex: 'getIndexPageData',
	businessProduct: 'getIndexPageData',
	businessDeals: 'getDealsPageData',
	businessDeal: 'getDealPageData',
	businessDealClaim: 'getDealPageData',
	businessPhotos: 'getPhotosPageData',
	businessPhoto: 'getIndexPageData',
	businessPhotoList: 'getIndexPageData',
	businessCart: 'get',
	chainListingIndex: 'getIndexPageData',
	chainListingProduct: 'getIndexPageData',
	chainListingAbout: 'getInfoPageData',
	chainListingReviews: 'getReviewsPageData',
	chainListingDeals: 'getDealsPageData',
	chainListingDeal: 'getDealPageData',
	chainListingDealClaim: 'getDealPageData',
	chainListingPhotos: 'getPhotosPageData',
	chainListingPhoto: 'getIndexPageData',
	chainListingPhotoList: 'getIndexPageData',
	chainListingCart: 'get'
}

export default {
	type: 'Business',

	getPage: async function (routeTo) {
		if (routeToFunctionMap[routeTo.name]) {
			const postData = {
				type: this.type,
				func_name: routeToFunctionMap[routeTo.name],
				data: {
					seo_url: routeTo.params?.business || '',
					deal_id: routeTo.params?.deal_id || routeTo.params?.claimHash,
					chainSeoUrl: routeTo.params?.chain || ''
				},
				firstLoad: routeTo.firstLoad || false
			}
			return await apiService.get(postData)
		}
	},

	toggleFavorite: async function (data) {
		let functionName = 'favoriteBusiness'
		if (!data.listing_id) {
			data.listing_id = data.businessId
		}
		if (data.current_user_favorited) {
			functionName = 'unfavoriteBusiness'
		}
		const postData = {
			type: this.type,
			func_name: functionName,
			data: data
		}
		const response = await this.apiCall(postData)
		return response.data
	},

	async reportBusiness(data) {
		return await apiService.get({
			type: this.type,
			func_name: 'submitReport',
			data
		})
	}
}
