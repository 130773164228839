import axios from 'axios'

import { createApolloClient } from '@/api/apollo/client.js'
import { API_SERVER, MANAGER_PORTAL_URL } from '@/constants/index.js'
import { ERROR } from '@/constants/toast/type.js'
import { GetAuth, LogIn, LogOut } from '@/gql/queries/auth.gql'
import { logError } from '@/utils/error-handling.js'

axios.defaults.withCredentials = true
const apolloClient = createApolloClient()

const initialState = {
	displayName: '',
	id: 0,
	loggedIn: false,
	username: '',
	profile: {},
	email: '',
	showChat: false,
	businessOwner: false,
	managerPortalUrl: MANAGER_PORTAL_URL,
	verifiedPhone: false,
	profilePicture: ''
}

const mapRestAuthData = data => {
	return {
		displayName: data.display_name || data.username,
		email: data.email,
		showChat: data.show_chat,
		verifiedPhone: data.verified_phone,
		id: data.user_id,
		username: data.username || data.user_id,
		isBusinessOwner: !!data.business_owner,
		image: {
			fileName: data.profile?.profile_picture
		},
		profile: { ...data.profile }
	}
}

const updateUserState = ({ state, auth }) => {
	if (auth.id && auth.id > 0) {
		state.id = auth.id
		state.loggedIn = true
		state.username = auth.username || auth.id
		state.displayName = auth.displayName
		state.email = auth.email
		state.verifiedPhone = auth.verifiedPhone
		state.showChat = auth.showChat
		state.businessOwner = auth.isBusinessOwner
		state.profilePicture = auth?.image?.fileName
	} else {
		state.id = 0
		state.loggedIn = false
	}
}

const updateUserProfile = (state, authData) => {
	if (authData.profile) {
		state.profile = { ...authData.profile }
	}
}

const auth = {
	namespaced: true,
	state: () => ({
		...initialState
	}),
	mutations: {
		setAuth(state, { auth }) {
			if (!auth) {
				for (const key in initialState) {
					state[key] = initialState[key]
				}
				state.loggedIn = false
			} else {
				const authData = auth.user_id ? mapRestAuthData(auth) : auth
				updateUserState({ state, auth: authData })
				updateUserProfile(state, authData)
			}
		},
		setVerified(state) {
			state.verifiedPhone = true
		}
	},
	actions: {
		async logIn({ commit }, { username, password }) {
			try {
				await apolloClient.query({
					query: LogIn,
					variables: {
						username,
						password
					},
					fetchPolicy: 'network-only'
				})
				const userRes = await apolloClient.query({
					query: GetAuth,
					fetchPolicy: 'network-only'
				})
				commit('setAuth', userRes.data)
			} catch (error) {
				const message = error.message.replace('GraphQL error: ', '')

				commit('toast/showToast', {
					primaryText: 'There was a problem logging you in',
					secondaryText: message,
					type: ERROR
				}, { root: true })

				logError(error)
			}
		},
		async logOut({ commit }) {
			try {
				await apolloClient.query({
					query: LogOut,
					fetchPolicy: 'network-only'
				})
				commit('setAuth', false)
			} catch (error) {
				const message = error.message.replace('GraphQL error: ', '')

				commit('toast/showToast', {
					primaryText: 'There was a problem logging you out',
					secondaryText: message,
					type: ERROR
				}, { root: true })

				logError(error)
			}
		},
		async loginWithFacebook({ commit }, data) {
			const token = data.authResponse.accessToken
			const expirationTimestamp = data.authResponse.expiresIn

			try {
				const loginResponse = await axios.post(
					`${API_SERVER}/oauth_connect.php?a=${token}&e=${expirationTimestamp}&ajax=true`
				)
				if (loginResponse.data?.success) {
					const userRes = await apolloClient.query({
						query: GetAuth,
						fetchPolicy: 'network-only'
					})

					if (userRes.data) {
						commit('setAuth', userRes.data)
						commit('setAuthQuery', userRes)
					}
				}
				return loginResponse
			} catch (e) {
				logError(e)
			}
		},
		setVerified({ commit }) {
			commit('setVerified')
		}
	},
	getters: {
		auth: state => state,
		isLoggedIn: state => state.loggedIn
	}
}

export default auth
