import stateParamMatch from '@/constants/state/state-param-match.js'

const stateRoutes = [
	{
		path: '/:country(united-states|canada)?',
		component: () => import(/* webpackChunkName: "states" */ './_Shell.vue'),
		children: [
			{
				path: `:state(${stateParamMatch})`,
				name: 'statePage',
				component: () => import(/* webpackChunkName: "states" */ './Index.vue')
			},
			{
				path: '',
				name: 'countryPage',
				component: () => import(/* webpackChunkName: "states" */ './Country.vue')
			}
		]
	}
]

export default stateRoutes
