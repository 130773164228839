import getters from './getters.js'
import mutations from './mutations.js'

const initialState = {
	isOpen: false,
	closePrevented: false,
	activeModalId: ''
}

export default {
	state: { ...initialState },
	namespaced: true,
	getters,
	mutations
}
