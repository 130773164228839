const chainRoutes = [
	{
		path: '/dispensaries/:chain',
		name: 'chainListing',
		component: () => import(/* webpackChunkName: "chain" */ './ChainIndex.vue')
	},
	{
		path: '/dispensaries/:chain/:business',
		props: true,
		component: () => import('@/views/business/_BusinessShell.vue'),
		children: [
			{
				path: ':indication(medical|recreational)?',
				props: true,
				name: 'chainListingIndex',
				component: () => import(/* webpackChunkName: "business" */ '@/views/business/BusinessIndex.vue')
			},
			{
				path: ':indication(medical|recreational)?/:productId([0-9]+)?',
				props: true,
				name: 'chainListingProduct',
				component: () => import(/* webpackChunkName: "businessProduct" */ '@/views/business/BusinessProduct.vue'),
				meta: {
					dontShowBusinessHeader: true
				}
			},
			{
				path: 'gallery',
				name: 'chainListingPhotos',
				component: () => import(/* webpackChunkName: "businessPhotos" */ '@/views/business/BusinessPhotos.vue')
			},
			{
				path: 'deals/view/:deal_id',
				props: true,
				name: 'chainListingDeal',
				component: () => import(/* webpackChunkName: "businessDeals" */ '@/views/business/BusinessDealView.vue')
			},
			{
				path: 'deals/view/:deal_id/claimed/:claimHash',
				props: true,
				name: 'chainListingDealClaim',
				component: () => import(/* webpackChunkName: "businessDeals" */ '@/views/business/BusinessDealView.vue')
			},
			{
				path: 'deals',
				name: 'chainListingDeals',
				component: () => import(/* webpackChunkName: "businessDeals" */ '@/views/business/BusinessDeals.vue')
			},
			{
				path: 'reviews',
				name: 'chainListingReviews',
				component: () => import(/* webpackChunkName: "businessReviews" */ '@/views/business/BusinessReviews.vue')
			},
			{
				path: 'cart',
				name: 'chainListingCart',
				component: () => import(/* webpackChunkName: "businessCart" */ '@/views/business/BusinessCart.vue'),
				meta: {
					noHeader: true
				}
			},
			{
				path: ':garbage',
				name: 'chain301',
				component: () => import('@/views/business/301.vue')
			}
		]
	}
]

export default chainRoutes
