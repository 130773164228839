import { LOGIN_MODAL } from '@/constants/modal/names.js'

const requireLogin = {
	bind: function(el, binding, vnode) {
		const vm = vnode.context
		const availableEvents = [ 'submit', 'click' ]
		const params = binding.value
		let bindTo = 'submit'
		let data = {}

		if (params.bindTo && availableEvents.indexOf(params.bindTo) > -1) {
			bindTo = params.bindTo
		}

		el.addEventListener(bindTo, (event) => {
			event.preventDefault()

			if (vm.$store.state.auth.loggedIn !== true && el.dataset.guestsAllowed !== 'true') {
				const loginNeeded = true
				vm.$store.commit('modal/showModal', LOGIN_MODAL)

				vm.$watch('$store.state.modal.activeModalId', function (newVal, oldVal) {
					if (newVal === '' && oldVal === LOGIN_MODAL) {
						if (vm.$store.state.auth.loggedIn) {
							data = JSON.parse(el.getAttribute('require-login-data')) || {}
							params.callback(data, loginNeeded)
						}
					}
				}
				)
			} else {
				data = JSON.parse(el.getAttribute('require-login-data')) || {}
				const loginNeeded = false
				params.callback(data, loginNeeded)
			}
		})
	}
}

export default requireLogin
