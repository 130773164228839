const animateOnScroll = {
	bind(el, binding) {
		const options = binding.value || {}
		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					el.classList.add(options.animation || 'fadeInUp')
					observer.unobserve(el)
				}
			})
		})
		observer.observe(el)
	}
}

export default animateOnScroll
