import { ERROR } from '@/constants/toast/type.js'

const invalidInput = {
	bind(el, binding, vnode) {
		el.addEventListener('invalid', () => {
			vnode.context.$store.commit('toast/showToast', {
				primaryText: 'Invalid Input',
				secondaryText: binding.value,
				type: ERROR
			})
		})
	}
}

export default invalidInput
