<template>
	<div
		aria-live="assertive"
		class="fixed inset-0 z-50 flex items-end px-4 pt-16 mb-12 pointer-events-none sm:items-start md:mb-0"
	>
		<div class="flex flex-col items-center w-full sm:items-end">
			<ww-toast
				v-for="toast in toasts"
				:key="toast.id"
				:toast-id="toast.id"
				:primary-text="toast.primaryText"
				:secondary-text="toast.secondaryText"
				:link="toast.link"
				:type="toast.type"
			/>
		</div>
	</div>
</template>

<script async>
import { mapState } from 'vuex'

import WwToast from '@/components/UI/WwToast.vue'

export default {
	components: {
		WwToast
	},
	computed: {
		...mapState('toast', [
			'toasts'
		])
	}
}
</script>
