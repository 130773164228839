import BUSINESS_ROUTES from '@/constants/business/business-routes.js'
import stateParamMatch from '@/constants/state/state-param-match.js'

// Map Filters
const features2 = 'delivery|pickup'
const features = 'online-ordering|recreational|open-now'
const amenities = 'age-18|age-19|age-21-only|accepts-credit-cards|has-atms|has-security|wheelchair-accessible|onsite-smoking|veteran-discount|ill-discount|online-ordering2|online-verification'
const specials = 'bud-deals|concentrate-deals|edible-deals|seed-deals|preroll-deals|vaporizer-deals|other-deals'
const menus = 'indicas|sativas|hybrids|concentrates|lab-tested|edibles|prerolls|clones|seeds'
const events = 'educational-event|recreational-event|other-event'
const sortBy = 'popular|distance|most-viewed|best-rated'

// Deals Filters
const types = 'recreational|medical'
const categories = 'concentrate-deals|edible-deals|flower-deals|pre-roll-deals|vaporizer-deals|other-deals'
const sizes = 'gram-deals|eighth-deals|half-deals|ounce-deals'

const dealsFilterString = `${types}|${categories}|${sizes}`

const filterString = `${features2}|${features}|${amenities}|${specials}|${menus}|${events}|${sortBy}`

const businessTypesRegex = 'businesses|' + BUSINESS_ROUTES // .replace(/-/g,'\\-')
const businessTypeRoutes = ':businessTypes(' + businessTypesRegex + '):businessTypes2([+][^/]+)?'

const routeA = ':filters(' + filterString + '):filters2([+].+)?:filters3([/].+)?/:page([0-9]+)?'
const routeB = ':filters(' + dealsFilterString + ')?:filters2([+].+)?:filters3([/].+)?/:page([0-9]+)?'

const cityRoutes = [ {
	path: `/:country(united-states|canada)?/:state(${stateParamMatch})?/:city/:zip([0-9]{5}|[a-z][0-9][a-z][0-9][a-z][0-9])?`,
	component: () => import(/* webpackChunkName: "home" */ './_Shell.vue'),
	props: true,
	children: [
		{
			path: '',
			props: true,
			name: 'cityIndex',
			component: () => import(/* webpackChunkName: "home" */ './Index.vue'),
			meta: {
				page: 'CITY'
			}
		},
		{
			path: 'marijuana-dispensary-deals/:page([0-9]+)?',
			name: 'cityDeals',
			component: () => import(/* webpackChunkName: "city" */ './Deals.vue')
		},
		{
			path: 'marijuana-dispensary-deals' + '/' + routeB,
			name: 'cityDealsWithFilters',
			component: () => import(/* webpackChunkName: "city" */ './Deals.vue')
		},
		{
			path: 'travel-guide',
			name: 'cityGuide',
			component: () => import('./Guide.vue')
		},
		{
			path: businessTypeRoutes + '/' + routeA,
			name: 'cityBusinessesTypeA',
			component: () => import(/* webpackChunkName: "city" */ './Businesses.vue'),
			meta: {
				noFooter: true,
				fullWidthHeader: true
			}
			// pathToRegexpOptions: { end: true }
		},
		{
			path: businessTypeRoutes + '/:page([0-9]+)?',
			name: 'cityBusinessesTypeSOLO',
			component: () => import(/* webpackChunkName: "city" */ './Businesses.vue'),
			meta: {
				noFooter: true,
				fullWidthHeader: true
			}
			// pathToRegexpOptions: { end: true }
		},
		{
			path: routeA,
			name: 'cityBusinessesTypeB',
			component: () => import(/* webpackChunkName: "city" */ './Businesses.vue'),
			meta: {
				noFooter: true,
				fullWidthHeader: true
			}
		}
	]
}
]

export default cityRoutes
