import { createApp } from './main.js'

const {
	APP, router, store
} = createApp()

if (window.__INITIAL_STATE__) {
	// We initialize the store state with the data injected from the server
	store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
	APP.$mount('#app')
})
