import axios from 'axios'

import { API_URL } from '@/constants/index.js'

const isNullish = (params) => {
	if (params) {
		Object.values(params).every(value => {
			if (value === null) return true
			return false
		})
	}
}

const handleTracking = (binding, vnode, paramName, paramValue) => {
	const vm = vnode.context
	if (!vm?.$gtag || !binding?.value) return

	if (binding.arg === 'external') {
		axios.post(
			`${API_URL}/TrackedExternalClicks/insert/`,
			{
				listingId: binding.value.listingId,
				destinationUrl: binding.value.destinationUrl
			}
		)
	} else if (binding.arg === 'custom') {
		if (paramValue) {
			vm.$gtag.customMap(binding.value.customMap)
			vm.$gtag.event(binding.value.event.name, { [paramName]: paramValue })
		}
	} else {
		vm.$gtag.event(binding.value.event.name, binding.value.event.params)
	}
}

const trackEvent = {
	isFn: true,
	bind(el, binding, vnode) {
		if (!isNullish(binding.value)) {
			const modifiers = binding.modifiers
			const paramName = binding.value?.event?.params?.paramName
			let paramValue = binding.value?.event?.params?.paramValue
			if (modifiers.keydown) {
				el.addEventListener(
					'keydown',
					event => {
						if (event.key === 'Enter') {
							if (binding.modifiers.search) {
								paramValue = vnode.context.$store.state.search.term
							}
							handleTracking(binding, vnode, paramName, paramValue)
						}
					}
				)
			}
			if (modifiers.click) {
				el.addEventListener('click', event => {
					if (event.type === 'click') {
						if (binding.modifiers.search) {
							paramValue = vnode.context.$store.state.search.term
						}
						handleTracking(binding, vnode, paramName, paramValue)
					}
				})
			}
		}
	},
	unbind(el, binding) {
		const modifiers = binding.modifiers
		if (modifiers.keydown) {
			el.removeEventListener('keydown', handleTracking)
		}
		if (modifiers.click) {
			el.removeEventListener('click', handleTracking)
		}
	}
}

export default trackEvent
