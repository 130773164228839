export const BUSINESS_PHOTO_MODAL = 'business-photo-modal'
export const REPORT_BUSINESS_MODAL = 'report-business-modal'
export const REPORT_REVIEW_MODAL = 'report-review-modal'
export const EDIT_REVIEW_MODAL = 'edit-review-modal'
export const DELETE_REVIEW_MODAL = 'delete-review-modAL'
export const USER_PHOTO_MODAL = 'user-photo-modal'
export const STRAIN_CHECKIN_MODAL = 'strain-checkin-modal'
export const REGISTER_MODAL = 'register-modal'
export const SMS_MODAL = 'sms-modal'
export const LOGIN_MODAL = 'login-modal'
export const DEAL_FILTER_MODAL = 'deal-filter-modal'
export const MENU_FILTER_MODAL = 'menu-filter-modal'
export const PRODUCT_MODAL = 'product-modal'
export const ADD_TO_CART_MODAL = 'add-to-cart-modal'
export const DELETE_FROM_CART_MODAL = 'delete-from-cart-modal'
export const DABVENT_MODAL = 'dabvent-modal'
export const NEWSLETTER_SIGNUP_MODAL = 'newsletter-signup-modal'

export default {
	EDIT_REVIEW_MODAL,
	BUSINESS_PHOTO_MODAL,
	REPORT_BUSINESS_MODAL,
	REPORT_REVIEW_MODAL,
	DELETE_REVIEW_MODAL,
	USER_PHOTO_MODAL,
	STRAIN_CHECKIN_MODAL,
	REGISTER_MODAL,
	SMS_MODAL,
	LOGIN_MODAL,
	DEAL_FILTER_MODAL,
	MENU_FILTER_MODAL,
	PRODUCT_MODAL,
	ADD_TO_CART_MODAL,
	DELETE_FROM_CART_MODAL,
	DABVENT_MODAL,
	NEWSLETTER_SIGNUP_MODAL
}
