import destructureStrain from '@/api/helpers/destructureStrain.js'

const initialState = {
	strain: {},
	strains: [],
	businesses: [],
	pageCount: 0
}

const mutations = {
	SET: function (state, data) {
		if (data.strain) {
			const dsData = destructureStrain(data, 'marijuana', 'single') // 'marijuana' currently hardcoded as cannabisType. API should return 'marijuana' for Where's Weed, 'hemp' for Where's CBD
			Object.assign(state, dsData)
		}

		if (data.strains) {
			const strains = []

			for (let i = 0; i < data.strains.length; i++) {
				const dsData = destructureStrain(data.strains[i], 'marijuana', 'list')
				strains.push(dsData)
			}
			state.strains = strains
		}

		if (data.page_count) {
			state.pageCount = data.page_count
		}

		if (data.businesses) {
			state.businesses = data.businesses
		}
	},
	PAGE: function (state, data) {
		const currentStrains = state.strains || []
		const newStrains = data.strains
		for (let i = 0; i < newStrains.length; i++) {
			const dsData = destructureStrain(newStrains[i], 'marijuana', 'list')
			currentStrains.push(dsData)
		}
	}
}

const actions = {
	SET: function (context, data) {
		context.commit('SET', data)
	},
	PAGE: function (context, data) {
		context.commit('PAGE', data)
	}
}
const strain = {
	namespaced: true,
	state: () => {
		return initialState
	},
	mutations,
	actions
}

export default strain
