import { logError } from '@/utils/error-handling.js'

export default {
	isLoading: ({ isLoading }) => isLoading,
	businessApiParam: state => { return state.seo_url },
	businessId: state => { return parseInt(state.listing_id) },
	menuRaw: state => { return state.menu },
	products: state => { return state.products },
	categories: state => { return state.categories },
	menu: (state) => {
		const tempMenu = []
		if (!state.menu?.length) return tempMenu
		try {
			state.menu.forEach(function (category) {
				if (category.products && category.products.length > 0) {
					tempMenu.push({
						category: category.category.menu_category_id,
						products: category.products
					})
				}
			})
			return tempMenu
		} catch (e) {
			logError(e)
		}
	},
	city: state => state.city,
	state: state => state.state_name,
	deals: state => { return state.deals },
	info: state => { return state.info },
	reviews: state => { return state.reviews },
	business: state => { return state },
	name: state => { return state.name },
	features: state => { return state.features },
	day: state => { return state.day },
	dealView: state => { return state.dealView },
	cart: state => { return state.cart },
	photos: state => { return state.photos },
	businessEdit: state => {
		const tempState = Object.assign({}, state) // doing this so we can manipulate vars for edit without changing state

		const oldImage = state.image
		// get rid of some vars we don't want to post
		delete tempState.image
		delete tempState.is_open
		delete tempState.creator_id
		delete tempState.approved
		delete tempState.has_features

		if (state.newImage) {
			tempState.image = state.newImage
		}
		return Object.assign({}, tempState, {
			hours: state.hoursEdit,
			features: state.featuresEdit,
			oldImage: oldImage
		})
	},
	featuresEdit: state => { return state.featuresEdit },
	hoursEdit: state => { return state.hoursEdit },
	cityStateString: state => {
		if (state.state_short === 'DC') return state.state_name
		return `${state.city}, ${state.state_short}`
	},
	deliveryMinimum: state => { return parseFloat(state.delivery_minimum) },
	categoriesExpanded: state => { return state.categoriesExpanded },
	hasCategories: state => { return state.hasCategories },
	defaultIndication: state => { return state.defaultIndication },
	hasMultipleIndications: state => { return state.hasMultipleIndications }
}
