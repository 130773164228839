const destructureCartSettings = function (settings) {
	const {
		ordering_guests_allowed: guestsAllowed,
		pickup,
		tax_rate: taxRate,
		delivery,
		delivery_prices: deliveryPrices,
		has_ordering: hasOrdering,
		hours_delivery: deliveryHours,
		ordering_ids_optional: idsOptional,
		ordering_verify_phones: verifyPhones,
		ordering_time_before_close: beforeClose,
		ordering_time_from_now: fromNow,
		ordering_time_future_days: futureDays,
		medcard_required: medcardRequired,
		ordering_license_required: licenseRequired
	} = settings

	const deliveryPricesArray = []
	if (typeof deliveryPrices === 'object') {
		const deliveryPricesKey = Object.keys(deliveryPrices)
		deliveryPricesKey.forEach(key => {
			deliveryPricesArray.push({
				id: Number(key),
				price: deliveryPrices[key].price,
				title: deliveryPrices[key].title
			})
		})
	}

	return {
		guestsAllowed,
		pickup,
		taxRate: parseFloat(taxRate) || 0,
		hasOrdering,
		idsOptional,
		verifyPhones,
		medcardRequired,
		licenseRequired,
		delivery: {
			available: delivery,
			prices: deliveryPricesArray,
			hours: deliveryHours
		},
		orderTiming: {
			beforeClose: parseInt(beforeClose) || 0,
			fromNow: parseInt(fromNow) || 0,
			futureDays: parseInt(futureDays) || 0
		}
	}
}

export default destructureCartSettings
