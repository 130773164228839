/* eslint-disable no-console */

import { logError } from '@/utils/error-handling.js'

try {
	if (navigator) {
		try {
			if ('serviceWorker' in navigator) {
				navigator.serviceWorker.register('/serviceWorker.js')
					.then((reg) => {
						// registration worked
					}).catch((error) => {
						// registration failed
						logError(error)
					})
			}
		} catch (e) {
			logError(e)
		}
	}
} catch (e) {
	//
}
