import { InMemoryCache } from 'apollo-cache-inmemory'

import { IS_SERVER } from '@/constants/index.js'

export function createCache() {
	const cache = new InMemoryCache()

	if (!IS_SERVER) {
		if (typeof window !== 'undefined') {
			const state = window.__APOLLO_STATE__
			if (state) {
				cache.restore(state.defaultClient)
			}
		}
	}
	return cache
}
