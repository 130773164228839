const userRoutes = [
	{
		path: '/users/:user',
		component: () => import(/* webpackChunkName: "user" */ './_Shell.vue'),
		children: [
			{ path: '/', name: 'userIndex', component: () => import(/* webpackChunkName: "user" */ './Reviews.vue') },
			{ path: 'reviews', name: 'userReviews', component: () => import(/* webpackChunkName: "user" */ './Reviews.vue') },
			// { path: 'about', name: 'userAbout', component: () => import(/* webpackChunkName: "user" */ './About.vue') },
			{ path: 'edit', name: 'userEdit', component: () => import(/* webpackChunkName: "user" */ './Edit.vue') },
			// { path: 'following', name: 'userFollowing', component: () => import(/* webpackChunkName: "user" */ './Following.vue') },
			{ path: 'photos', name: 'userPhotos', component: () => import(/* webpackChunkName: "user" */ './Photos.vue') },
			{ path: 'strains', name: 'userStrains', component: () => import(/* webpackChunkName: "user" */ './Strains.vue') },
			{
				path: 'pre-orders',
				name: 'userPreOrders',
				component: () => import(/* webpackChunkName: "user" */ './PreOrders.vue')
			},
			{ path: 'pre-orders/:order_id', name: 'userIndividualOrder', component: () => import(/* webpackChunkName: "user" */ './PreOrdersIndividual.vue') },
			{ path: 'favorites', name: 'userFavorites', component: () => import(/* webpackChunkName: "user" */ './UserFavorites.vue') }
		]
	}
]

export default userRoutes
