import { initialState } from './constants.js'

const baseMutations = {
	resetState(state) {
		const defaultState = initialState()
		for (const stateProperty in defaultState) {
			if (typeof defaultState[stateProperty] === 'object') {
				state[stateProperty] = Object.assign({}, defaultState[stateProperty])
			} else {
				state[stateProperty] = defaultState[stateProperty]
			}
		}
	}
}

const routeMutations = {
	setIsLoading(state, isLoading) {
		state.isLoading = isLoading
	},
	setIndexPageData(state, data) {
		if (!data.menu) throw new Error('Menu data missing')
		state.menu = data.menu
		state.products = data.products
		state.photos = data.images
		state.nearbyBusinesses = data.nearby_businesses
	},
	setReviewsPageData(state, data) {
		if (!data.reviews) throw new Error('Reviews data missing')
		state.reviews = data.reviews
	},
	setPhotosPageData(state, data) {
		if (!data.images) throw new Error('Photos data missing')
		state.photos = data.images
	},
	setDealsPageData(state, data) {
		if (!data.deals) throw new Error('Deals data missing')
		state.deals = data.deals
	},
	setDealViewPageData(state, data) {
		if (!data.deal) throw new Error('Deal data missing')
		state.dealView = data.deal
	},
	setCommonData(state, data) {
		const businessData = data.business
		const businessDataMissing = 'Business data missing'
		if (typeof businessData !== 'object') throw businessDataMissing
		for (const obj in businessData) {
			state[obj] = businessData[obj]
		}
		state.listing_id = parseInt(state.listing_id)
		if (data.images) { // set image data for business profile header's carousel
			state.photos = data.images
		}
	},
	setDefaultIndication(state, indication) {
		state.defaultIndication = indication
	},
	setHasMultipleIndications(state, bool) {
		state.hasMultipleIndications = bool
	},
	setCategoriesAndId(state, categories, id) {
		state.categories = categories
		if (id) {
			state.listing_id = parseInt(id)
		}
	}
}

const userInitiatedMutations = {
	setCategoriesExpanded(state, isExpanded) {
		state.categoriesExpanded = isExpanded
	},
	setHasCategories(state, hasCategories) {
		state.hasCategories = hasCategories
	},
	appendReview(state, data) {
		const currentReviews = state.reviews || {}
		let postedReviewObject = {}
		const newObject = {}
		postedReviewObject = data.results.posted_review
		postedReviewObject.vote = ''
		currentReviews.ids.unshift(postedReviewObject.reviewid)
		currentReviews.items = Object.assign(newObject, currentReviews.items, { [postedReviewObject.reviewid]: postedReviewObject })
	},
	setUserFavorited(state, data) {
		const currentVote = data.favorited_business.listing_id.current_user_favorited
		state.current_user_favorited = currentVote
	},
	setReportBizReview(state, data) {
		const currentReviews = state.reviews || {}
		let reportedReview = {}
		reportedReview = data.results.reported_review
		if (currentReviews.items[reportedReview.reviewid]) {
			currentReviews.items[reportedReview.reviewid].reported = 1
		}
	}
}

export default { ...baseMutations, ...routeMutations, ...userInitiatedMutations }
