export const BUSINESS_TYPES = [
	{
		url: 'dispensary',
		label: 'Dispensary',
		icon: 'shop.svg',
		component: 'Dispensary',
		value: 'dispensary'
	},
	{
		url: 'delivery',
		label: 'Delivery Service',
		icon: 'delivery.svg',
		component: 'Delivery',
		value: 'delivery'
	},
	{
		url: 'clinic',
		label: 'Doctor',
		icon: 'doctors.svg',
		component: 'Doctor',
		value: 'clinic'
	},
	{
		url: 'smoke-shop',
		label: 'Smoke Shop',
		icon: 'smokeshops.svg',
		component: 'SmokeShop',
		value: 'smoke-shop'
	}
]

export const PARTNERS = [
	{ label: 'Adilas', value: 'Adilas' },
	{ label: 'Adilas', value: 'Blaze' },
	{ label: 'Flowhub', value: 'Flowhub' },
	{ label: 'Greenbits', value: 'Greenbits' },
	{ label: 'MJ Freeway', value: 'MJ Freeway' },
	{ label: 'Proteus420', value: 'Proteus420' },
	{ label: 'Treez', value: 'Treez' }
]

export default { BUSINESS_TYPES, PARTNERS }
