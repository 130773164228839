import { DROPDOWN_GROUPS } from '@/constants/map/map-filters.js'

const destructureMapFilters = function ({ filters, activeFilters }) {
	const tempGroups = []
	let tempFilters = {}
	let sortBy = {}
	let amenities = {}
	let type = {}

	for (let filterGroupIndex = 0; filterGroupIndex < filters.ids.length; filterGroupIndex++) { // filters.ids is an ordered list of filter group ids
		const tempGroupId = filters.ids[filterGroupIndex]
		const filterSet = filters.items[tempGroupId].ids.map(filterId => {
			let active = false
			if (activeFilters.indexOf(filterId) > -1) {
				active = true
			}

			return {
				id: filterId,
				active: active,
				text: filters.items[tempGroupId].items[filterId].name,
				value: filterId,
				type: 'checkbox'
			}
		})

		const tempGroupObject = {
			id: tempGroupId,
			name: tempGroupId !== 'features' && filters.items[tempGroupId].name, // this is a temp change to remove the double features display.  Filters need revisited (hopefully with graph but maybe sooner)
			filters: filterSet
		}

		if (DROPDOWN_GROUPS.includes(tempGroupId)) {
			switch (tempGroupId) {
				case 'sort_by':
					sortBy = Object.assign({}, tempGroupObject)
					break
				case 'amenities':
					amenities = Object.assign({}, tempGroupObject)
					break
				case 'type':
					type = Object.assign({}, tempGroupObject)
					break
				default:
					break
			}
		} else {
			tempGroups.push(tempGroupObject)
		}

		tempFilters = { ...tempFilters, ...filters.items[tempGroupId].items } // filters.items is an object of filters keyed by the filters.ids values
	}

	return {
		tempGroups, tempFilters, sortBy, amenities, type
	}
}

export default destructureMapFilters
