import { BASE_INDEX } from './constants.js'

const incrementHighlightedIndex = ({ commit, state }) => {
	commit('setHighlightedIndex', state.highlightedIndex + 1)
}

const decrementHighlightedIndex = ({ commit, state }) => {
	commit('setHighlightedIndex', state.highlightedIndex - 1)
}

const resetHighlightedIndex = ({ commit }) => {
	commit('resetHighlightedIndex', BASE_INDEX)
}

const setIndexCount = ({ commit }, count) => {
	commit('setIndexCount', count)
}

const setHighlightedRoute = ({ commit }, route) => {
	commit('setHighlightedRoute', route)
}

const setSearchTerm = ({ commit }, term) => {
	commit('setSearchTerm', term)
}

export default {
	incrementHighlightedIndex, decrementHighlightedIndex, setIndexCount, resetHighlightedIndex, setHighlightedRoute, setSearchTerm
}
