import actions from './actions.js'
import { BASE_INDEX } from './constants.js'
import getters from './getters.js'
import mutations from './mutations.js'

const initialState = {
	term: '',
	highlightedIndex: BASE_INDEX,
	highlightedRoute: '/',
	numberOfIndexes: 0,
	isSearchOpen: false
}

export default {
	state: { ...initialState },
	namespaced: true,
	getters,
	mutations,
	actions
}
