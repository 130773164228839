<template>
	<div
		id="app"
		ref="app"
	>
		<ErrorPage
			v-if="showError"
			:status="error.status"
		/>
		<template v-else>
			<router-view />
			<AgeGate v-if="showAgeGate" />
		</template>
		<WwToaster v-if="isMounted" />
	</div>
</template>

<script asnyc>
import { mapGetters, mapMutations } from 'vuex'

import AgeGate from '@/components/singleUse/AgeGate.vue'
import WwToaster from '@/components/UI/WwToaster.vue'
import { ERROR } from '@/constants/toast/type.js'
import { GetAuth } from '@/gql/queries/auth.gql'
import { logError } from '@/utils/error-handling.js'

import BREAKPOINTS from '.././config/breakpoints.json'

const ERROR_CODES = [ 404, 500, 501, 502, 503, 504 ]

const APP_USER_AGENTS = /WheresWeedAndroid/

export default {
	name: 'App',
	components: {
		AgeGate,
		ErrorPage: () => import('@/components/error/Error.vue'),
		WwToaster
	},
	data () {
		return {
			isMounted: false
		}
	},
	apollo: {
		auth: {
			query: GetAuth,
			update(data) {
				if (data.auth) {
					this.$store.commit('auth/setAuth', { auth: data.auth })
				}
			},
			prefetch: false
		}
	},
	computed: {
		...mapGetters('slideOver', [ 'activeSlideOverId' ]),
		...mapGetters('modal', [ 'activeModalId' ]),
		showError() {
			return ERROR_CODES.includes(this.error?.status) && this.isMounted
		},
		...mapGetters('auth', [ 'auth' ]),
		...mapGetters([ 'meta', 'error' ]),
		userAgent() { return navigator && navigator.userAgent },
		isBot() { return (/crawl|googlebot|slurp|spider|bingbot|tracker|click|parser|applebot|yandex|semrushbot|ahrefsbot|insights|lighthouse/.test(this.userAgent.toLowerCase())) },
		isInApp() { return (APP_USER_AGENTS).test(this.userAgent)	},
		showAgeGate() { return this.isMounted && !this.isInApp && !this.isBot }
	},
	metaInfo() {
		return this.meta.metaInfo
	},
	watch: {
		error(newError) {
			if (!newError) return
			if (newError.redirect) {
				this.$router.push(newError.redirect)
			} else if (newError?.message) {
				this.showToast({
					primaryText: 'Server Error',
					secondaryText: newError.message,
					type: ERROR
				})
			}
		},
		'$route'() {
			this.clearActiveModal()
			this.$store.commit('removeError')
		},
		activeSlideOverId(newValue) {
			this.preventOverflow(newValue)
		},
		activeModalId(newValue) {
			this.preventOverflow(newValue)
		}
	},
	mounted() {
		this.isMounted = true
		this.addMatchMediaListeners()
	},
	errorCaptured(err) {
		logError(err)
	},
	methods: {
		...mapMutations('toast', [ 'showToast' ]),
		...mapMutations('modal', [ 'clearActiveModal' ]),
		preventOverflow(activeSlideOverId) {
			if (activeSlideOverId) {
				document.body.style.overflow = 'hidden'
			} else {
				document.body.style.overflow = 'auto'
			}
		},
		addMatchMediaListeners() {
			const MML = {}
			if (typeof window === 'undefined') return
			MML.xs = window.matchMedia(`(max-width: ${BREAKPOINTS.xs}px)`)
			MML.sm = window.matchMedia(`(min-width: ${BREAKPOINTS.sm[0]}px) and (max-width: ${BREAKPOINTS.sm[1]}px)`)
			MML.md = window.matchMedia(`(min-width: ${BREAKPOINTS.md[0]}px) and (max-width: ${BREAKPOINTS.md[1]}px)`)
			MML.lg = window.matchMedia(`(min-width: ${BREAKPOINTS.lg[0]}px) and (max-width: ${BREAKPOINTS.lg[1]}px)`)
			MML.xl = window.matchMedia(`(min-width: ${BREAKPOINTS.xl}px)`)

			const setMatch = (match) => {
				if (match.matches) {
					this.$store.commit('setMediaMatch', match.mediaName || match?.currentTarget?.mediaName)
				}
			}

			for (const key in MML) {
				if (typeof MML[key] === 'undefined') continue
				MML[key].addListener(setMatch, key)
				MML[key].mediaName = key
				setMatch(MML[key])
			}
		}
	}
}

</script>

<style lang="scss" scoped>

.max-w-full img {
	max-width: 100%;
}
// NOTE #Brad added 11/18/20 this to stop a long url in /delaware from breaking everything
p {
	word-wrap: break-word;
	hyphens: auto;
}
// ENDNOTE

html,
body,
#app {
	height: 100%;
}
#app {
	text-align: center;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: #2c3e50;
}
#nav {
	padding: 30px;
	a {
		font-weight: bold;
		color: #2c3e50;
		&.router-link-exact-active {
			color: #42b983;
		}
	}
}
.vue-star-rating {
	max-width: 140px;
}
.vue-star-rating-star svg {
	width: 100% !important;
}
</style>
