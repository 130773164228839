import { BUSINESS_TYPES } from '@/constants/tour/index.js'

const businessTypesMatch = BUSINESS_TYPES.map(businessType => businessType.url).join('|')

const tourRoutes = [
	{
		path: '/tour',
		component: () => import(/* webpackChunkName: "tour" */ './_Shell.vue'),
		children: [
			{
				path: `/tour/:businessType(${businessTypesMatch})/:signup([^/]+)?`,
				name: 'tourFeatures',
				component: () => import(/* webpackChunkName: "tour" */ './Features.vue')
			}, {
				path: '/tour/duplicates/:signup([^/]+)',
				name: 'tourDuplicates',
				component: () => import(/* webpackChunkName: "tour" */ './Duplicates.vue')
			}, {
				path: '/thank-you',
				name: 'tourThankYou',
				component: () => import(/* webpackChunkName: "tour" */ './ThankYou.vue')
			}, {
				path: '/',
				name: 'tourIndex',
				component: () => import(/* webpackChunkName: "tour" */ './Index.vue')
			}
			// { path: '/tour/packages/:signup([^/]+)', name: 'tourPackages', component: () => import(/* webpackChunkName: "tour" */ './Packages') },
			// { path: '/tour/complete-signup/:signup([^/]+)', name: 'tourCreditCardForm', component: () => import(/* webpackChunkName: "tour" */ './CreditCardForm') }
		]
	}
]

export default tourRoutes
