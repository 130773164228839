import 'regenerator-runtime/runtime'

import Bugsnag from '@bugsnag/js'
import { buildAxiosFetch } from '@lifeomic/axios-fetch'
import { ApolloClient } from 'apollo-client'
import { onError } from 'apollo-link-error'
import { createHttpLink } from 'apollo-link-http'
import axios from 'axios'

import { createCache } from '@/api/apollo/cache.js'
import { APP_NODE_ENV, GQL_URL, IS_SERVER } from '@/constants/index.js'

export function createApolloClient() {
	const cache = createCache()

	const axiosInstance = axios.create({
		baseUrl: GQL_URL,
		withCredentials: true
	})

	const httpLink = createHttpLink({
		uri: GQL_URL,
		credentials: 'include',
		fetch: buildAxiosFetch(axiosInstance)
	})

	const errorLink = onError(({ response, networkError, graphQLErrors }) => {
		if (APP_NODE_ENV === 'development') { // log errors to console in development mode
			console.log('Error Link - response', response)
			console.log('Error Link - networkError', networkError)
			console.log('Error Link - graphQLErrors', graphQLErrors)
		}
		graphQLErrors?.forEach((error) => {
			if (error.redirect?.code === 301) {
				Object.assign(error, {
					code: 301,
					redirect: error?.redirect?.to ?? '/'
				})
			}
			const errorCode = error?.redirect?.code ?? 500
			const newError = new Error(`'Error Link - '${errorCode}`, { cause: error })

			if (errorCode != 301) { // send errors to Bugsnag if not a 301 redirect
				Bugsnag.notify(newError)
			}
		})
		if (networkError) {
			const error500 = new Error('Error Link - 500', { cause: networkError })
			Bugsnag.notify(error500)
		}
	})

	const ssrOptions = { ssrForceFetchDelay: 100, ssrMode: false }
	if (IS_SERVER) {
		ssrOptions.ssrForceFetchDelay = 0
		ssrOptions.ssrMode = true
	}

	const defaultOptions = {
		query: {
			errorPolicy: 'all'
		},
		watchQuery: {
			errorPolicy: 'all'
		}
	}

	return new ApolloClient({
		link: errorLink.concat(httpLink),
		cache: cache,
		connectToDevTools: true,
		...ssrOptions,
		defaultOptions: defaultOptions
	})
}
