<template>
	<transition
		enter-active-class="transition duration-300 ease-out transform"
		enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
		enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
		leave-active-class="transition duration-100 ease-in"
		leave-from-class="opacity-100"
		leave-to-class="opacity-0"
	>
		<div class="w-full max-w-sm mt-0 overflow-hidden bg-white rounded-lg shadow-lg pointer-events-auto ring-1 ring-black ring-opacity-5">
			<div
				:class="['p-4 relative w-full',
					{ 'cursor-pointer': link }
				]"
				@click.prevent="handleLinkClick"
			>
				<div class="flex items-center py-2">
					<div class="w-1/12 mr-2 md:mr-0">
						<div class="flex">
							<div class="items-center h-full shrink-0">
								<div
									class="text-green-400"
									aria-hidden="true"
								>
									<CheckCircle
										v-if="type === SUCCESS"
										class="w-8 h-8 text-green-500"
									/>
									<AlertCircle
										v-else-if="type === ERROR"
										class="w-8 h-8 text-red-500"
									/>
								</div>
							</div>
						</div>
					</div>

					<div class="w-11/12">
						<div class="flex-1 px-3 md:space-y-1">
							<p class="text-sm font-semibold text-black">
								{{ primaryText }}
							</p>
							<p
								v-if="secondaryText"
								class="text-sm text-gray-500"
							>
								{{ secondaryText }}
							</p>
						</div>
					</div>
				</div>
				<div class="absolute top-0 right-0">
					<button
						class="w-6 h-6 m-2 text-xs font-bold text-black bg-white border rounded-full"
						@click.prevent="handleClose"
					>
						<span class="sr-only">
							Close
						</span>
						✕
					</button>
				</div>
			</div>
		</div>
	</transition>
</template>

<script async>
import { mapMutations } from 'vuex'

import AlertCircle from '@/components/icons/AlertCircle.vue'
import CheckCircle from '@/components/icons/CheckCircle.vue'
import { ERROR, SUCCESS } from '@/constants/toast/type.js'

export default {
	components: {
		AlertCircle,
		CheckCircle
	},
	props: {
		toastId: {
			type: String,
			required: true
		},
		primaryText: {
			type: String,
			default: ''
		},
		secondaryText: {
			type: String,
			default: ''
		},
		type: {
			type: String,
			default: SUCCESS
		},
		link: {
			type: Object,
			default: () => ({})
		}
	},
	data() {
		return {
			SUCCESS,
			ERROR
		}
	},
	methods: {
		...mapMutations('toast', [ 'closeToast' ]),
		handleClose(event) {
			event.stopPropagation()
			this.closeToast(this.toastId)
		},
		async handleLinkClick() {
			if (this.link) {
				this.$router.push(this.link)
				await this.$nextTick()
				this.closeToast(this.toastId)
			}
		}
	}
}
</script>
