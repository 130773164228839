const blogRoutes = [
	{
		path: '/blog',
		component: () => import(/* webpackChunkName: "blogs" */ './_Shell.vue'),
		children: [
			{
				path: '/authors/:authorName',
				name: 'blogAuthorPage',
				component: () => import(/* webpackChunkName: "blogs" */ './Author.vue')
			},
			{
				path: ':page([0-9]+)?',
				name: 'blogIndex',
				component: () => import(/* webpackChunkName: "blogs" */ './Index.vue')
			},
			{
				path: ':category/:page([0-9]+)?',
				name: 'blogCategory',
				component: () => import(/* webpackChunkName: "blogs" */ './Index.vue')
			},
			{
				path: ':category?/:year/:month/:article',
				name: 'blogArticle',
				component: () => import(/* webpackChunkName: "blogs" */ './Article.vue')
			}
		]
	}
]

export default blogRoutes
