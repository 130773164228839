export default {
	showModal(state, modalId) {
		state.activeModalId = modalId
		state.isOpen = true
	},
	preventClose(state) {
		state.closePrevented = true
	},
	allowClose(state) {
		state.closePrevented = false
	},
	closeModal(state) {
		if (!state.closePrevented) {
			state.isOpen = false
			state.activeModalId = ''
		}
	},
	clearActiveModal(state) {
		state.isOpen = false
		state.activeModalId = ''
	}
}
