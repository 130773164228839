import businessApiService from '@/api/public/businessService.js'
import { APP_META } from '@/constants/index.js'

import { routeToMutationMap } from './constants.js'

const { BASE_URL, NAME } = APP_META

const isBusinessMenu = routeTo => {
	return routeTo.name === 'businessIndex' // NOTE if menu is loaded we already have all product info. // && !routeTo.params.productId
}

const handleMenuData = ({ commit, routeTo }) => {
	if (isBusinessMenu(routeTo)) {
		commit('setIsLoading', true)
	}
}

const reportBusiness = async (_, reportInfo = {}) => {
	return await businessApiService.reportBusiness(reportInfo)
}

const baseActions = {
	resetState({ commit }) {
		commit('resetState')
	}
}

const pageActions = {
	async getPage({
		state, rootState, commit, dispatch
	}, routeTo) {
		routeTo = Object.assign({}, routeTo)
		let response = {}
		const apiService = businessApiService
		const routeAction = routeToMutationMap[routeTo.name]

		// check to see if stuff is already loaded (via prefetch probably)
		if (!rootState.ssrPageLoaded) {
			routeTo.firstLoad = true
			commit('setSsrPageLoad', routeTo.path, { root: true })
		} else if (rootState.ssrPageLoaded === routeTo.path) {
			commit('setSsrPageLoad', true, { root: true })
			return
		}

		if (routeTo.isSameBusiness && isBusinessMenu(routeTo) && state.menu.length) {
			return
		}

		// determine what mutations to do based on which page you are on
		try {
			handleMenuData({
				state, commit, routeTo
			})
			response = await apiService.getPage(routeTo)

			if (response.error && response.error.response.status == 404) {
				commit('set404', null, { root: true })
				return
			} else if (response.error) {
				commit('setError', response.error, { root: true })
				return
			}

			if (typeof response.data !== 'object') {
				commit('setError', 'Unknown server error', { root: true })
				return
			}
			// every module that has routing should be calling this to set meta data and such
			if (routeAction === 'setDealViewPageData') {
				const { business, deal } = response.data?.results
				// add in deal view meta this sucks btw but just need ot get it out there
				const dealViewMeta = response.data.meta
				dealViewMeta.metaInfo.link = [ { rel: 'canonical', href: `${BASE_URL}${business?.url}` } ]
				dealViewMeta.metaInfo.title = `${deal.title} @ ${business?.name} - ${NAME}`
				dealViewMeta.metaInfo.meta = [ { rel: 'description', content: deal.description } ]
				response.data.meta = dealViewMeta
			}
			dispatch('setAppData', response.data, { root: true })
			commit('setCommonData', response.data.results)
			commit(routeAction, response.data.results)

			const {
				results: {
					cart: cartData
				},
				settings: cartSettings
			} = response.data

			if (typeof cartSettings !== 'undefined') {
				cartSettings.delivery = Boolean(state.delivery) // this might change if we move cart settings for delivery
			}
			// cartSettings.delivery = response.data.settings.delivery

			commit('cart/setCartData', { data: cartData, settings: cartSettings }, { root: true })
			commit('setIsLoading', false)
		} catch (e) {
			commit('setError', e, { root: true })
			commit('setIsLoading', false)
			return { success: false, error: e.response }
		}
		return { success: true }
	}
}

export default {
	...baseActions, ...pageActions, reportBusiness
}
