import actions from './actions.js'
import { initialState } from './constants.js'
import getters from './getters.js'
import mutations from './mutations.js'

export default {
	namespaced: true,
	state: initialState,
	getters,
	mutations,
	actions
}
