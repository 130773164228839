import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

import apiService from '@/api/index.js'
import { logError } from '@/utils/error-handling.js'
import mapGeoSections from '@/utils/mappers/geoSections.js'
import mapJsonLd from '@/utils/mappers/jsonLd.js'
import mapMetaLocation from '@/utils/mappers/metaLocation.js'

import modules from './modules/_moduleIndex.js'

Vue.use(Vuex, axios)

export function createStore() {
	const META_INFO_EMPTY = {
		title: 'Where\'s Weed',
		meta: [],
		link: []
	}
	return new Vuex.Store({
		modules: {
			...modules
		},
		state: () => ({
			errorCode: null,
			error: null,
			redirect: null,
			location: {
				name: null,
				url: '',
				lat: '',
				lon: ''
			},
			geoSections: [],
			meta: {
				h1: '',
				metaInfo: META_INFO_EMPTY
			},
			ssrPageLoaded: false,
			mediaMatch: 'sm',
			jsonld: '',
			lastRoute: '/'
		}),
		mutations: {
			setMeta: function (state, metaData) {
				if (typeof metaData !== 'object') return
				if (metaData.meta) { // gql mapping
					metaData.metaInfo = {
						meta: metaData.meta,
						title: metaData.title,
						h1: metaData.h1
					}
				}

				if (typeof metaData.metaInfo !== 'object') metaData.metaInfo = META_INFO_EMPTY

				try {
					if (Object.keys(metaData).includes('links')) {
						const { meta, links, title } = metaData
						metaData.metaInfo = { meta, link: links, title }
					}

					if (!Array.isArray(metaData?.metaInfo.link)) {
						metaData.metaInfo.link = []
					}
				} catch (e) {
					logError(e)
				}
				state.meta = Object.assign({}, metaData)
			},
			pushMetaLink(state, metaLink) {
				if (typeof metaLink !== 'object') {
					return
				}
				if (!state.meta?.metaInfo.link) {
					state.meta.metaInfo.link = []
				}
				state.meta.metaInfo.link.push(metaLink)
			},
			setLocation: function (state, location) {
				state.location = location
			},
			setGeoSections: function (state, geoStructure) {
				state.geoSections = [ ...geoStructure ]
			},
			set404: function (state) {
				state.errorCode = 404
			},
			remove404: function (state) {
				state.errorCode = null
			},
			setError: function (state, error) {
				if (!!error && typeof error === 'object') {
					state.error = error.message
					state.errorCode = error.status
					state.redirect = error.redirect
					return
				}
				if (typeof error !== 'string') return
				state.error = error
			},
			removeError: function (state) {
				state.error = null
				state.errorCode = null
				state.redirect = ''
			},
			setSsrPageLoad: function (state, ssrPage) {
				state.ssrPageLoaded = ssrPage
			},
			setMediaMatch: function (state, match) {
				state.mediaMatch = match
			},
			setjsonld: function (state, data) {
				state.jsonld = data
			},
			setLastRoute: function (state, lastRoute) {
				state.lastRoute = lastRoute
			}
		},
		actions: {
			async apiCall(context, data) {
				if (!context.state.ssrPageLoaded) {
					data.firstLoad = true
				}

				let returnData = false
				try {
					returnData = await apiService.get(data, context)
					context.dispatch('setAppData', returnData.data)
				} catch (e) {
					logError(e)
					if (e.response && e.response.status == 404) { // NOTE need to further develop this
						context.commit('set404')
					}
				}

				return returnData
			},
			newGet: async function (context, data) {
				let returnData = false
				try {
					returnData = await apiService.newGet(data)

					if (typeof returnData.data !== 'object') {
						context.dispatch('setError', { status: 503, message: 'Unknown server error' })
						throw new Error('Unknown server error')
					}

					context.dispatch('setAppData', returnData.data)
				} catch (e) {
					logError(e)
					if (e.response && e.response.status == 404) {
						context.commit('set404')
						// NOTE this is so the 404 can still return nearby business and recent blogs
						context.dispatch('setAppData', e.data)
					} else if (e.response) {
						context.commit('setError', e.response)
					} else {
						context.commit('setError', e)
					}
				}

				return returnData
			},
			newPost: async function (context, { endpoint, params }) {
				let returnData = false
				try {
					returnData = await apiService.newPost({
						endpoint, params, store: context
					})

					if (typeof returnData.data !== 'object') {
						throw new Error('Unknown server error')
					}

					context.dispatch('setAppData', returnData.data)
				} catch (e) {
					// logError(new Error(e))
					context.commit('setError', e)
				}

				return returnData
			},
			setSsrPageLoad: function (context, data) {
				context.commit('setSsrPageLoad', data)
			},
			setError: function (context, error) {
				if (error?.response && error.response.status == 404) {
					context.commit('set404')
				} else {
					context.commit('setError', error)
				}
			},
			setAppData: function (context, data) { // data passed in is the full response so check for top level items
				if (typeof data !== 'object') return

				if (data.meta) {
					context.commit('setMeta', data.meta)
				}
				if (data.location) {
					context.dispatch('setLocation', data.location)
				}
				if (data.geo_sections) {
					context.dispatch('setGeoSections', { geoSections: data.geo_sections, location: data.location })
				}
				if (data.auth) {
					context.commit('auth/setAuth', { auth: data.auth })
				}

				if (data?.meta?.metaInfo?.jsonLd) {
					context.dispatch('setJsonLd', data?.meta?.metaInfo?.jsonLd)
				} else if (data?.jsonld) {
					context.dispatch('setJsonLd', data.jsonld)
				}
			},
			setLocation: function (context, location) {
				const mappedLocation = mapMetaLocation(location)
				context.commit('setLocation', mappedLocation)
			},
			setJsonLd: function (context, data) {
				const jsonld = mapJsonLd(data)
				context.commit('setjsonld', jsonld)
			},
			setMeta: function (context, meta) {
				context.dispatch('setJsonLd', meta?.jsonLd)
				if (meta?.geoSections) {
					context.dispatch('setGeoSections', { geoSections: meta?.geoSections, location: meta.location })
				}
				context.commit('setMeta', meta)
			},
			setGeoSections: function (context, data) {
				const mappedGeoSections = mapGeoSections(data.geoSections, data.location)
				context.commit('setGeoSections', mappedGeoSections)
			}
		},
		getters: {
			geoSections: state => { return state.geoSections },
			ssrPageLoaded: state => { return state.ssrPageLoaded },
			meta: state => { return state.meta },
			mediaMatch: state => { return state.mediaMatch },
			location: state => { return state.location },
			isMobile: state => {
				return state.mediaMatch === 'xs' ||
					state.mediaMatch === 'sm'
			},
			isDesktop: state => {
				return state.mediaMatch === 'xl' ||
					state.mediaMatch === '2xl'
			},
			error: state => { return { status: state.errorCode, message: state.error } },
			cityCoordinates: state => { return { lat: state.location.lat, lon: state.location.lon } }
		}
	})
}
