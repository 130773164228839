const mapGeoSectionsREST = (geoSections, location) => {
	const geoStructure = []
	if (Array.isArray(geoSections?.ids?.sections)) {
		for (const geoKey of geoSections.ids.sections) {
			if (typeof geoSections?.data?.sections[geoKey] !== 'object') continue
			const { label, uri } = geoSections.data.sections[geoKey]
			geoStructure.push({ key: geoKey, name: label, url: uri })
		}
	}
	return geoStructure
}

const mapGeoSectionsGQL = (geoSections, location) => {
	return [ ...geoSections.filter(geoSection => !!geoSection.url) ]
}

export default (geoSections, location) => {
	let mappedGeoSections = []
	if (Array.isArray(geoSections)) {
		mappedGeoSections = [ ...mapGeoSectionsGQL(geoSections, location) ]
	} else if (typeof geoSections === 'object') {
		mappedGeoSections = [ ...mapGeoSectionsREST(geoSections, location) ]
	}
	mappedGeoSections.forEach((section) => {
		const tempUrl = section.url
		if (section.key === 'strains') {
			section.url = '/strains'
		} else if (section.key === 'blog') {
			section.url = '/blog'
		} else if (section.url?.indexOf('/') < 0) {
			section.url = `${location.url}/${tempUrl}`
		}
	})
	return mappedGeoSections
}
