import apiService from '@/api/index.js'

export default {

	type: 'Cart',
	async get(data) {
		const postData = {
			type: this.type,
			func_name: 'get',
			data: {
				listing_id: data
			}
		}
		return await apiService.get(postData)
	},
	async addToCart(data, store) {
		const postData = {
			type: this.type,
			func_name: 'add',
			data: data
		}
		return apiService.post(postData, store)
	},
	async removeFromCart(data, store) {
		const postData = {
			type: this.type,
			func_name: 'remove',
			data: data
		}
		return apiService.post(postData, store)
	},
	async updateCart(data, store) {
		const postData = {
			type: this.type,
			func_name: 'update',
			data: data
		}
		return apiService.post(postData, store)
	},
	async placeOrder(data, store) {
		const postData = {
			type: this.type,
			func_name: 'placeOrder',
			data: data
		}
		return apiService.post(postData, store)
	}
}
