import WwPackage from '../../package.json'

export const API_SERVER = process.env.VUE_APP_API_SERVER || 'https://wheresweed.com'
export const API_URL = `${API_SERVER}/api`
export const GQL_URL = `${API_SERVER}/api/gql.php`
export const API_SERVER_PORT = process.env.VUE_APP_API_SERVER_PORT
export const CONTENT_SERVER = process.env.VUE_APP_CONTENT_SERVER || ''
export const IS_SERVER = !!(process.env.VUE_ENV === 'server')
export const SERVER_PORT = process.env.VUE_APP_SERVER_PORT

export const GOOGLE_MAPS_KEY = process.env.VUE_APP_GOOGLE_MAPS_KEY || 'AIzaSyCOpHhQgQjPXVGB20oM6iKb_-CIKqF0ilM'
export const GOOGLE_MAPS_STATIC_KEY = process.env.VUE_APP_GOOGLE_MAPS_KEY || 'AIzaSyBhz7ngORstkEXBIx7U2bvYGwquoZjubj4'

export const FACEBOOK_APP_ID = '250587151664036'

export const APP_VERSION = WwPackage.version
export const IS_DEPLOYMENT = !!process.env?.VUE_APP_DEPLOYMENT

export const MANAGER_PORTAL_URL = 'https://legacy.wheresweed.com/my-listing'
export const APP_NODE_ENV = process.env?.VUE_APP_NODE_ENV_OVERRIDE || process.env.NODE_ENV

export const APP_META = {
	NAME: 'Where\'s Weed',
	DOMAIN_NAME: 'WheresWeed.com',
	DOMAIN: 'wheresweed.com',
	EMAIL: 'contact@wheresweed.com',
	BASE_URL: 'https://wheresweed.com'
}
