import { BASE_INDEX } from './constants.js'

const setHighlightedIndex = (state, indexValue) => {
	if (indexValue <= BASE_INDEX) indexValue = state.numberOfIndexes - 1
	else if (indexValue >= state.numberOfIndexes) indexValue = BASE_INDEX
	state.highlightedIndex = indexValue
}

const resetHighlightedIndex = (state) => {
	state.highlightedIndex = BASE_INDEX
}

const setIndexCount = (state, count) => {
	state.numberOfIndexes = count
}

const setHighlightedRoute = (state, route) => {
	state.highlightedRoute = route
}

const setSearchIsOpen = (state, isOpen) => {
	state.isSearchOpen = isOpen
}

const setSearchTerm = (state, term) => {
	state.term = term
}

export default {
	setHighlightedIndex, setIndexCount, resetHighlightedIndex, setHighlightedRoute, setSearchIsOpen, setSearchTerm
}
