import stateParamMatch from '@/constants/state/state-param-match.js'

const type = 'indica|sativa|hybrid|recreational'
const sort = 'best-rating|trending'

// let filterString = `${type}|${sort}`

// const route1 = ":filters(" + filterString + ")"

const strainRoutes = [
	{
		path: '/strains',
		component: () => import(/* webpackChunkName: "strains" */ './_Shell.vue'),
		children: [
			{
				// path: ':filters(' + `${type}` + ')?:filters2([+][^/]+)?/:sortBy(' + `${sort}` + ')?',
				path: `:filters(${type})?:filters2([+][^/]+)?/:sortBy(${sort})?`,
				name: 'strainIndexA',

				component: () => import(/* webpackChunkName: "strains" */ './Index.vue')
			},
			{
				path: ':sortBy(best-rating|trending)?',
				name: 'strainIndex',

				component: () => import(/* webpackChunkName: "strains" */ './Index.vue')
			},
			// REVIEW how to keep :strain from accepting city names as strain names?
			{
				path: ':strain',
				name: 'strainIndividual',
				props: true,
				component: () => import(/* webpackChunkName: "strains" */ './Individual.vue')
			}
		]
	},
	{
		path: `/:country(united-states|canada)?/:state(${stateParamMatch})?/:city/strains`,
		component: () => import(/* webpackChunkName: "strains" */ './_Shell.vue'),
		children: [
			{
				path: `:filters(${type})?:filters2([+][^/]+)?/:sortBy(${sort})?`,
				name: 'strainByLocation',
				component: () => import(/* webpackChunkName: "strains" */ './Index.vue')
			},
			{
				path: `:sortBy(${sort})?`,
				name: 'strainByLocationB',
				component: () => import(/* webpackChunkName: "strains" */ './Index.vue')
			}
		]
	}
]

export default strainRoutes
