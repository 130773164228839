const setSearchMode = (state, mode) => {
	state.searchMode = mode
}

const setSelectedBusiness = (state, business) => {
	state.selectedBusiness = business
}

export default {
	setSearchMode, setSelectedBusiness
}
