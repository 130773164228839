import BUSINESS_ROUTES from '@/constants/business/business-routes.js'
import stateParamMatch from '@/constants/state/state-param-match.js'

const businessRoutes = [
	{
		path: `/:country(united-states|canada)?/:state(${stateParamMatch})?/:city/:businessType(${BUSINESS_ROUTES})/:business`,
		props: true,
		component: () => import(/* webpackChunkName: "business" */ '@/views/business/_BusinessShell.vue'),
		children: [
			{
				path: '/',
				props: true,
				name: 'businessIndex',
				component: () => import(/* webpackChunkName: "business" */ '@/views/business/BusinessIndex.vue')
			},
			{
				path: 'products/:productId([0-9]+)?',
				props: true,
				name: 'businessProduct',
				component: () => import(/* webpackChunkName: "businessProduct" */ '@/views/business/BusinessProduct.vue'),
				meta: {
					dontShowBusinessHeader: true
				}
			},
			{
				path: 'gallery',
				name: 'businessPhotos',
				component: () => import(/* webpackChunkName: "businessPhotos" */ '@/views/business/BusinessPhotos.vue')
			},
			{
				path: 'deals/view/:deal_id', props: true, name: 'businessDeal', component: () => import(/* webpackChunkName: "businessDeals" */ '@/views/business/BusinessDealView.vue')
			},
			{
				path: 'deals/view/:deal_id/claimed/:claimHash', props: true, name: 'businessDealClaim', component: () => import(/* webpackChunkName: "businessDeals" */ '@/views/business/BusinessDealView.vue')
			},
			{
				path: 'deals', name: 'businessDeals', component: () => import(/* webpackChunkName: "businessDeals" */ '@/views/business/BusinessDeals.vue')
			},
			{
				path: 'reviews', name: 'businessReviews', component: () => import(/* webpackChunkName: "businessReviews" */ '@/views/business/BusinessReviews.vue')
			},
			{
				path: 'cart',
				name: 'businessCart',
				component: () => import(/* webpackChunkName: "businessCart" */ '@/views/business/BusinessCart.vue'),
				meta: {
					noHeader: true
				}
			},
			{
				path: ':garbage',
				name: 'business301',
				component: () => import('@/views/business/301.vue')
			}
		]
	}
]

export default businessRoutes
