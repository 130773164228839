import { logError } from '@/utils/error-handling.js'

const destructureOrder = function (order) {
	try {
		const {
			adjusted_price: adjustedPrice,
			// business info
			business_address: businessAddress,
			business_name: businessName,
			business_city: businessCity,
			business_state: businessState,
			business_thumbnail: businessThumbnail,
			listing_id: businessId,
			seo_url: businessUrl,
			business_zip: businessZip,
			biz_notes: businessNotes,
			business_phone: businessPhone,
			directions_link: directionsLink,
			// customer info
			phone: customerPhone,
			name: customerName,
			delivery_address: customerAddress,
			delivery_city: customerCity,
			delivery_state: customerState,
			delivery_zip: customerZip,
			user_id: customerId,
			customer_profile: customerProfile,
			// order info
			order_id: orderId,
			num_items: itemCount,
			subtotal,
			status,
			tax,
			items,
			verification_needed: verificationNeeded,
			order_type: orderType,
			// dispatch details (pickup/delivery)
			type,
			delivery_price: deliveryPrice,
			delivery_zone: deliveryZone,
			// order timing
			creation_time: timePlaced,
			time_estimated_end: timeEstimatedEnd,
			time_estimated_start: timeEstimatedStart,
			time_requested: timeRequested,
			deals,
			timezone
		} = order

		return {
			business: {
				address: businessAddress,
				name: businessName,
				state: businessState,
				city: businessCity,
				id: businessId,
				url: businessUrl,
				zip: businessZip,
				notes: businessNotes,
				phone: businessPhone,
				directionsLink: directionsLink,
				thumbnail: businessThumbnail
			},
			customer: {
				name: customerName,
				phone: customerPhone,
				address: customerAddress,
				city: customerCity,
				state: customerState,
				zip: customerZip,
				id: customerId,
				profile: customerProfile
			},
			timing: {
				placed: timePlaced,
				requested: timeRequested,
				estimated: {
					start: timeEstimatedStart,
					end: timeEstimatedEnd
				},
				timezone
			},
			id: orderId,
			orderType,
			itemCount,
			status,
			subtotal: parseFloat(subtotal),
			adjustedPrice,
			verificationNeeded,
			dispatch: {
				type: type,
				price: parseFloat(deliveryPrice),
				zone: deliveryZone
			},
			tax: parseFloat(tax),
			items,
			deals: deals && typeof deals === 'object' && Object.values(deals)
		}
	} catch (e) {
		logError(e)
	}
}

export default destructureOrder
