export const BUSINESS_TYPE_ROUTES = {
	dispensary: 'marijuana-dispensaries',
	delivery: 'marijuana-delivery',
	clinic: 'marijuana-doctors',
	smoke: 'smoke-shop',
	seed: 'marijuana-seeds',
	cbd: 'cbd-stores'
}
export const BUSINESS_ROUTES = 'map|' + Object.values(BUSINESS_TYPE_ROUTES).join('|')

export default BUSINESS_ROUTES
