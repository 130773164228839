// first we need to import the api
import destructureCartSettings from '@/api/helpers/destructureCartSettings.js'
import destructureOrder from '@/api/helpers/destructureOrder.js'
import cartApiService from '@/api/public/cartService.js'
import { logError } from '@/utils/error-handling.js'

const emptyCart = {
	items: [],
	num_items: 0,
	subtotal: 0,
	settings: {},
	orderPlaced: false,
	lastAddedItem: {},
	itemToRemove: {},
	tax: 0.00,
	name: '',
	logo: '',
	url: '',
	listingId: ''
}
const temporaryCart = {
	items: [],
	num_items: 0,
	subtotal: 0,
	name: '',
	logo: '',
	url: '',
	listingId: '',
	settings: {}
}

const cart = {
	namespaced: true,
	// set the modules local state and define vars we want reactive
	state: () => ({
		// anything not listed will not be reactive without a completely new object.assign (or something of the like)
		...emptyCart,
		temporaryCart: { ...temporaryCart }
	}),
	mutations: {
		// FIXME cart and it's items still needs desctructured
		setCartData: function (state, { data, settings, orderPlaced, action, newItem }) {
			if (!data && action !== 'stageItemToRemove') { // sometimes the api responds false and other times nothing at all...
				data = emptyCart
				state.settings = Object.assign({})
			}
			if (typeof data === 'object') {
				state.items = data.items
				state.num_items = parseInt(data.num_items)
				state.subtotal = parseFloat(data.subtotal)
				state.tax = parseFloat(data.tax)
				state.temporaryCart.items = [ ...data.items ]
				state.temporaryCart.num_items = parseInt(data.num_items)
				state.temporaryCart.subtotal = parseFloat(data.subtotal)
				state.temporaryCart.tax = parseFloat(data.tax)
			}

			if (action === 'addToCart') {
				const lastItem = newItem
				state.lastAddedItem = Object.assign({}, lastItem)
			}

			if (action === 'stageItemToRemove') {
				const itemToRemove = newItem
				state.itemToRemove = Object.assign({}, itemToRemove)
			}

			if (typeof settings === 'object') {
				state.settings = Object.assign({}, destructureCartSettings(settings))
			}

			if (typeof orderPlaced === 'object') {
				state.orderPlaced = Object.assign({}, destructureOrder(orderPlaced))
			} else {
				state.orderPlaced = false
			}
		},
		updateCartData: function (state, data) {
			state.items = data.items // this add object .assign on it at one point but it is an array
			state.num_items = parseInt(data.num_items)
			state.subtotal = parseFloat(data.subtotal)
			state.name = data.name
			state.logo = data.logo
			state.url = data.url
			state.listingId = data.listingId
			state.settings = { ...data.settings }
		},
		updateTemporaryCartData: function ({ temporaryCart }, data) {
			temporaryCart.items = data.items
			temporaryCart.num_items = parseInt(data.num_items)
			temporaryCart.subtotal = parseFloat(data.subtotal)
			temporaryCart.name = data.name
			temporaryCart.logo = data.logo
			temporaryCart.url = data.url
			temporaryCart.listingId = data.listingId
			temporaryCart.settings = { ...data.settings }
		}
	},
	actions: {
		async get(context, listingId) {
			try {
				const response = await cartApiService.get(listingId)
				context.commit('setCartData', { data: response.data.results, settings: response.settings })
			} catch (e) {
				logError(e)
			}
		},
		async addToCart(context, data) {
			try {
				const response = await cartApiService.addToCart(data, context)
				context.commit('setCartData', {
					data: response.data.results,
					action: 'addToCart',
					newItem: data.items[0],
					settings: response.data.settings
				})
				return response
			} catch (e) {
				context.dispatch('setError', e, { root: true })
				return { success: false }
			}
		},
		stageItemToRemove(context, data) {
			context.commit('setCartData', {
				action: 'stageItemToRemove',
				newItem: data
			})
		},
		async removeFromCart(context, data) {
			try {
				const response = await cartApiService.removeFromCart(data, context)
				context.commit('setCartData', { data: response.data.results, settings: response.data.settings })
				return response
			} catch (e) {
				context.commit('setError', e, { root: true })
			}
		},
		async updateCart(context, data) {
			try {
				const response = await cartApiService.updateCart(data, context)
				context.commit('setCartData', { data: response.data.results })
				return response
			} catch (e) {
				context.commit('setError', e, { root: true })
			}
		},
		async placeOrder(context, data) {
			try {
				const response = await cartApiService.placeOrder(data, context)
				if (response.data.success === true) {
					context.commit('setCartData', { data: emptyCart, orderPlaced: response.data.results.order })
				} else {
					const error = (response.errors && response.errors[0]) || 'Unknown server error'
					context.commit('setError', error, { root: true })
				}
				return response.data
			} catch (e) {
				context.commit('setError', e, { root: true })
				return { success: false }
			}
		}
	},
	getters: { // getters give us an easy way to grab this data in components and is more explicit about what we plan to use
		cart: state => { return state },
		orderPlaced: state => { return state.orderPlaced },
		temporaryCart: state => { return state.temporaryCart }
	}
}

export default cart
