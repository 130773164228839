import destructureBlogArticles from '@/api/helpers/destructureBlogArticles.js'
import destructureDeal from '@/api/helpers/destructureDeal.js'
import destructureMapFilters from '@/api/helpers/destructureMapFilters.js'

// break up our different types of mutations for organization
const routeMutations = {
	setBlogs: function (state, data) {
		state.blogArticles = destructureBlogArticles(data)
	},
	setBusinesses: function (state, data) {
		state.businesses = data
	},
	setCbdBusinesses: function (state, data) {
		state.cbd = data
	},
	setDeliveryBusinesses: function (state, data) {
		state.deliveries = data
	},
	setFaqs: function (state, data) {
		state.faqs = data
	},
	setFiltersArray: function (state, data) {
		state.filters = data
	},
	setFilters: function (state, data) {
		const {
			tempGroups, tempFilters, sortBy, amenities, type
		} = destructureMapFilters(data)
		state.filterSet = Object.assign({}, {
			quickFilters: tempGroups,
			allFilters: tempFilters,
			dropdowns: {
				sortBy: sortBy, amenities: amenities, type: type
			}
		})
	},
	setBaseInfo: function (state, data) {
		state.introText = data.intro_text
	},
	setPageCount: function (state, data) {
		// state.businesses = data.results.businesses
		state.pageCount = data
		// state.filters = data.results.filters_data
		// state.filters.ids = state.filters.ids.filter(filterType => filterType != 'sort_by')
	},
	setDeals: function (state, data) {
		const mappedDeals = data.map(deal => { return destructureDeal(deal) })
		state.deals = mappedDeals
	},
	appendBusinesses: function (state, businesses) {
		// detect if there was a result, if not return
		if (Array.isArray(businesses)) return // i dont love it but it gets set to an empty array rather than an object

		state.businesses.ids.push(...businesses.ids)
		state.businesses.items = Object.assign({}, state.businesses.items, businesses.items)

		state.businesses = Object.assign({}, state.businesses)
	},
	setDealsPageCount: function (state, data) {
		state.dealsPageCount = data
	}
}

const city = {
	namespaced: true,
	state: () => ({
		cityName: '',
		introText: '',
		businesses: {
			ids: [],
			items: {}
		},
		cbd: {
			ids: [],
			items: {}
		},
		deliveries: {
			ids: [],
			items: {}
		},
		blogArticles: [],
		deals: [],
		dealsPageCount: 0,
		faqs: [],
		filters: [],
		filterSet: {},
		pageCount: false
	}),
	mutations: {
		...routeMutations
	},
	actions: {
		SET: function (context, { data, currentUrl }) {
			if (data.businesses_page_count) {
				context.commit('setPageCount', parseInt(data.businesses_page_count))
			}
			if (data.filters) {
				context.commit('setFiltersArray', data.filters)
			}

			if (data.filters_data) { // REVIEW this data is returned by the API as 'filters' now so this is not going to work. Need to revisit what we are trying to accomplish here. [-Bo 12/4/20]
				if (!currentUrl) return
				const activeFilters = currentUrl.substring(1).split(/[+/]/) || []
				context.commit('setFilters', { filters: data.filters_data, activeFilters })
			}
			if (data.blogs) {
				context.commit('setBlogs', data.blogs)
			}
			if (data.businesses) {
				context.commit('setBusinesses', data.businesses)
			}
			if (data.cbd) {
				context.commit('setCbdBusinesses', data.cbd)
			}
			if (data.deliveries) {
				context.commit('setDeliveryBusinesses', data.deliveries)
			}
			if (data.faqs) {
				context.commit('setFaqs', data.faqs)
			}
			if (data.deals) {
				context.commit('setDeals', data.deals)
				if (data.page_count) {
					context.commit('setDealsPageCount', data.page_count)
				}
			}
			if (data.city) {
				context.commit('setBaseInfo', data.city)
			}
		},
		NEXT_PAGE: function (context, data) {
			if (data.deals) {
				// context.commit('appendDeals', data.deals)
				context.commit('setDeals', data.deals)
				if (data.page_count) {
					context.commit('setDealsPageCount', data.page_count)
				}
			}
			if (data.businesses) {
				context.commit('appendBusinesses', data.businesses)
			}
		}
	},
	getters: { // getters give us an easy way to grab this data in components and is more explicit about what we plan to use
		businesses: state => { return state.businesses },
		cbd: state => { return state.cbd },
		deliveries: state => { return state.deliveries },
		faqs: state => { return state.faqs },
		blogArticles: state => { return state.blogArticles },
		cityDeals: state => { return state.deals },
		dealsPageCount: state => { return state.dealsPageCount },
		introText: (state) => { return state.introText },
		pageCount: state => { return state.pageCount },
		filters: state => { return state.filters },
		// use rootState to import 'location' from store\index.js to keep the root store module from getting cluttered
		locationName: (state, getters, rootState) => { return rootState.location.name },
		cityPageData: (state, getters, rootState) => {
			let dispensaryBusinesses = []
			let deliveryBusinesses = []
			if (typeof state.businesses?.items === 'object') {
				dispensaryBusinesses = Object.values(state.businesses?.items)
			}
			if (typeof state.deliveries?.items === 'object') {
				deliveryBusinesses = Object.values(state.deliveries?.items)
			}
			const { state: stateInfo, city: cityInfo } = rootState.location
			return {
				name: state.cityName,
				blogArticles: state.blogArticles,
				dispensaryBusinesses,
				deliveryBusinesses,
				description: state.introText,
				location: {
					name: rootState.location.name,
					city: {
						url: rootState.location.url
					},
					state: {
						url: stateInfo.url
					}
				},
				state: {
					name: stateInfo.name
				},
				hasCityGuide: cityInfo.has_guide,
				faqs: state.faqs
			}
		}
	}
}

export default city
