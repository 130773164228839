export const routeToMutationMap = {
	businessIndex: 'setIndexPageData',
	businessProduct: 'setIndexPageData',
	businessReviews: 'setReviewsPageData',
	businessDeals: 'setDealsPageData',
	businessDeal: 'setDealViewPageData',
	businessDealClaim: 'setDealViewPageData',
	businessCart: 'setCartPageData',
	businessPhotos: 'setPhotosPageData',
	businessPhoto: 'setIndexPageData',
	businessPhotoList: 'setIndexPageData',
	chainListingIndex: 'setIndexPageData',
	chainListingProduct: 'setIndexPageData',
	chainListingAbout: 'setDetailsPageData',
	chainListingReviews: 'setReviewsPageData',
	chainListingDeals: 'setDealsPageData',
	chainListingDeal: 'setDealViewPageData',
	chainListingDealClaim: 'setDealViewPageData',
	chainListingCart: 'setCartPageData',
	chainListingPhotos: 'setPhotosPageData',
	chainListingPhoto: 'setIndexPageData',
	chainListingPhotoList: 'setIndexPageData',
	businessTour: 'NO_MUTATION'
}

export const initialState = () => ({
	creatorid: 0,
	isLoading: true,
	listing_id: 0,
	name: '',
	phone: '',
	type: '',
	hours: [],
	hoursEdit: [],
	is_open: false,
	welcome_hidden: '',
	url: '',
	thumbnail: '',
	directions_link: '',
	city: '',
	state: '',
	zip: '',
	city_url: '',
	state_name: '',
	type_name: '',
	type_url: '',
	current_user_favorited: false,
	static_map: '',
	website: '',
	facebook_url: '',
	twitter_url: '',
	pintrest_url: '',
	instagram_url: '',
	about: 'default state, works?',
	address: '',
	menu: [],
	products: {},
	selectedProduct: null,
	availableFeatures: {},
	features: {},
	featuresEdit: {},
	geopoints: {
		ids: [],
		items: {}
	},
	image: '',
	info: [],
	deals: [],
	rating_types: [],
	review_average: '',
	review_averages: [],
	review_count: 0,
	reviews: {
		ids: [],
		items: {}
	},
	seo_url: '',
	photos: {},
	day: false,
	dealView: {},
	verified: 0,
	closed: 0,
	nearbyBusinesses: [],
	delivery_minimum: 0,
	categoriesExpanded: false,
	hasCategories: null,
	defaultIndication: '',
	hasMultipleIndications: false,
	categories: []
})
