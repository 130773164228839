import { logError } from '@/utils/error-handling.js'

const destructureBlogArticles = function (articles) {
	try {
		const tempArticles = []
		if (articles.ids) {
			for (let i = 0; i < articles.ids.length; i++) {
				const articleId = articles.ids[i]
				const tempArticle = articles.items[articleId]

				if (tempArticle.image) {
					tempArticle.image = tempArticle.image.split('/').pop()
				}
				tempArticles.push(tempArticle)
			}
		}
		return tempArticles
	} catch (e) {
		logError(e)
	}
}

export default destructureBlogArticles
