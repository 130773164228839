import axios from 'axios'

import { API_URL } from '@/constants/index.js'
import { logError, showErrorToast } from '@/utils/error-handling.js'

axios.defaults.withCredentials = true

class AxiosError extends Error {
	constructor(error) {
		super(error)
		this.message = error.response?.data?.errors || 'Unknown server error'
		this.status = error.response?.status
		this.redirect = error.response?.data?.redirect || error.response?.data?.data?.redirect
	}
}

const checkParams = (params) => {
	try {
		if (!params.type) {
			throw new Error('Api parameters missing type')
		}
		if (!params.func_name) {
			throw new Error(
				'Api parameters missing function' + (process.env.NODE_ENV === 'development' ? JSON.stringify(params) : '')
			)
		}
		return `/${params.type}/${params.func_name}`
	} catch (e) {
		throw logError(e)
	}
}

const validateResponse = response => {
	if (response.data.errors) {
		let errorText = ''
		for (const i in response.data.errors) {
			errorText += `${response.data.errors[i].message} \n`
		}
		throw new Error(errorText)
	}
	return response
}

const checkForResponseData = ({
	response, url, params
}) => {
	if (!response.data) {
		throw new Error({
			msg: 'Response did not return data.',
			response,
			endpoint: url,
			params
		})
	}
}

const setHeaders = (axios, response) => {
	if (response.headers['set-cookie'] && process.env.VUE_ENV === 'server') {
		axios.defaults.headers.common.cookie = response.headers['set-cookie']
	}
}

/**
 * @name apiService
 * This is the API index
 * It sets the url and exports a couple of functions for calling the api
 */
export default {
	async get(params, store) {
		const endpoint = API_URL + (params.endpoint ? '/' + params.endpoint : '') + checkParams(params)

		const response = await axios.get(endpoint, {
			params: {
				firstLoad: params.firstLoad || false,
				...params.data
			},
			withCredentials: true
		}).catch(
			error => {
				throw new AxiosError(error)
			}
		)

		if (response.headers['set-cookie'] && process.env.VUE_ENV === 'server') {
			axios.defaults.headers.common.cookie = response.headers['set-cookie']
		}

		if (response.data.errors) {
			let errorText = ''
			for (const i in response.data.errors) {
				errorText += `${response.data.errors[i].message} \n`
			}
			showErrorToast(errorText, store)
			throw errorText
		}
		if (!response.data) {
			throw new Error({
				msg: 'Response did not return data.',
				response,
				endpoint,
				params
			})
		}
		return response
	},
	async post(params, store) {
		const endpointUrl = API_URL + (params.endpoint ? '/' + params.endpoint : '') + checkParams(params)
		delete (params.type)
		delete (params.endpoint)
		delete (params.func_name)

		const response = await axios.post(
			endpointUrl,
			params,
			{ withCredentials: true }
		).catch(
			error => {
				throw error
			}
		)

		if (response.data.errors) {
			let errorText = ''
			for (const i in response.data.errors) {
				errorText += `${response.data.errors[i].message || response.data.errors[i]} \r\n`
			}
			showErrorToast(errorText, store)
			throw errorText
		}
		return response
	},
	/**
	 * a new get takes the endpoint as a string rather than attempting to build it with nonsensically named variables
	 * @param {String} endpoint
	 */
	async newGet({ endpoint, params }) {
		const url = `${API_URL}/${endpoint}`

		const	response = await axios.get(url, { params }).catch(
			error => {
				throw new AxiosError(error)
			}
		)
		try {
			validateResponse(response)
			checkForResponseData({
				response,
				url,
				params
			})
			setHeaders(axios, response)
		} catch (error) {
			logError(error)
		}

		return response
	},
	async newPost({
		endpoint, params, store
	}) {
		try {
			const response = await axios.post(`${API_URL}/${endpoint}`, params)
			validateResponse(response)
			return response
		} catch (error) {
			if (store) {
				showErrorToast(error, store)
			}
			logError(error)
		}
	}
}
