const RECREATIONAL = 'Rec'
const MEDICAL = 'Med'
const BOTH = 'both'

export const destructureDeal = (deal) => {
	let listingName = false
	let listingUrl = false
	let listingId = 0
	const {
		business_name: nameREST,
		business_url: urlREST,
		image,
		title,
		indication,
		medical,
		recreational,
		category: categoryREST,
		productCategory,
		claim_count: claimCountREST,
		dealClaimCount,
		deal_id: dealIdREST,
		amount,
		id,
		description
	} = deal

	if (deal?.listing) {
		listingName = deal.listing.name
		listingUrl = deal.listing.url
		listingId = deal.listing.id
	}

	const getIndication = (indication, medical, recreational) => { // Return indication short as 'med' or 'rec'
		if (indication) { // this case is for REST only
			return indication === BOTH
				? false // dont show indication if both Medical and Recreational
				: indication // REST sends indication short as 'med' or 'rec' (which is what we want to display)
		}

		if (medical && recreational) return false // dont show indication if both Medical and Recreational
		if (medical) return MEDICAL
		if (recreational) return RECREATIONAL
	}

	return {
		name: listingName || nameREST,
		url: listingUrl || urlREST,
		listingId: listingId,
		image,
		title,
		amount,
		indication: getIndication(indication, medical, recreational),
		medical,
		recreational,
		description,
		category: categoryREST || productCategory,
		dealClaimCount: claimCountREST || dealClaimCount?.toString(),
		id: dealIdREST || id
	}
}

export default destructureDeal
