const ripple = {
	bind(el, binding) {
		el.addEventListener(
			'click',
			event => {
				const rippleColor = binding.value || 'rgba(255,255,255,0.5)'
				const useDark = binding.arg === 'dark' ? 'rgba(0, 0, 0, 0.3)' : false
				el.style.position = 'relative'
				el.style.overflow = 'hidden'
				createElement(event, rippleColor, useDark)
			}
		)
	},
	unbind(el) {
		el.removeEventListener('click', createElement)
	}
}

const createElement = (event, rippleColor, useDark) => {
	const button = event.currentTarget

	const circle = document.createElement('span')
	const diameter = Math.max(button.clientWidth, button.clientHeight)
	const radius = diameter / 2

	circle.style.width = circle.style.height = `${diameter}px`
	circle.style.left = `${event.clientX - button.offsetLeft - radius}px`
	circle.style.top = `${event.clientY - button.offsetTop - radius}px`
	circle.style.backgroundColor = useDark || rippleColor
	circle.classList.add('ripple')

	const rippleEffect = button.getElementsByClassName('ripple')[0]

	if (rippleEffect) {
		rippleEffect.remove()
	}

	button.appendChild(circle)
}

export default ripple
