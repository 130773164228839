export default {
	showSlideOver(state, slideOverId) {
		state.activeSlideOverId = slideOverId
		state.isOpen = true
	},
	preventClose(state) {
		state.closePrevented = true
	},
	allowClose(state) {
		state.closePrevented = false
	},
	closeSlideOver(state) {
		if (!state.closePrevented) {
			state.isOpen = false
			state.activeSlideOverId = ''
		}
	}
}
