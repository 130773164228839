export default (location) => {
	if (location.lat) {
		return location
	} else {
		return {
			lat: location.latitude,
			lon: location.longitude,
			url: location.url,
			name: location.name
		}
	}
}
